
import * as ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // ES6
import "react-quill/dist/quill.core.css";
import { useEffect } from 'react';
import Rating from "react-rating"
import { getTranslationByKey } from "../global"

export function RadioQuestions(props) {

    useEffect(() => {
        if (props.q.answer_id && props.q.answer_id != '') {
            let radiobtn = document.getElementById(props.q.answer_id);
            radiobtn.checked = true;
        } else {
            var elements = document.getElementsByTagName("input");

            for (var i = 0; i < elements.length; i++) {
                if (elements[i].type == "radio") {
                    elements[i].checked = false;
                }
            }
        }
    }, [])

    return (
        <div>
            <div className="bold opportunities_label_feedbackform">{getTranslationByKey(props.q.question)}{props.q.required ? '*' : ''}</div>
            <div className="flex" Style="margin: 10px 0">
                {props.q.options.map((o, index) =>
                    <div className="radio-item">
                        <input type="radio" id={"ques" + props.index + "_" + o} name={"ques" + props.index} value={"ropt" + props.index} onClick={(e) => props.handleCheckbox(e, props.q.id, o)} />
                        <label for={"ques" + props.index + "_" + o} Style="margin-left: 5px;">{o}</label>
                    </div>
                )}
            </div>
        </div>
    )
}

export function OpenQuestions(props) {
    const Quill = ReactQuill.Quill
    const modules = {
        toolbar: false,
    }
    return (
        <div className="opportunities_question_row">
            <div className="bold opportunities_label_feedbackform">{getTranslationByKey(props.q.question)}{props.q.required ? '*' : ''}</div>
            <div className='likert_questions_options'>{getTranslationByKey(props.q.description)}</div>
            <div>
                <textarea type="text" maxlength="400" cols="60" rows="3" Style={props.style ? props.style : "margin-top: 10px; width: 100%;"} onChange={(e) => props.handleText(e, props.q.id)} />
            </div>
        </div>
    )
}

export function RatingQuestions(props) {
    return (
        <div className="opportunities_question_row">
            <div className="bold opportunities_label_feedbackform">{getTranslationByKey(props.q.question)}{props.q.required ? '*' : ''}</div>
            <div className='flex'>
                {props.q.options.map((o, index) =>
                    <div className='likert_questions_options'>{index + 1} - {getTranslationByKey(o)}</div>
                )}
            </div>
            <div Style="margin: 10px 0">
                <Rating
                    stop={props.q.likert_max}
                    onClick={(e) => props.handleRating(e, props.q.id)}
                    initialRating={props.q.answer ?? null}
                    emptySymbol={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(n => <span className="rating_question_number">{n}</span>)}
                    fullSymbol={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(n => <span className="rating_question_number rating_question_number_selected">{n}</span>)}
                />
            </div>
        </div >
    )
}