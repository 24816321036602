import React, { useEffect, useState, useRef } from "react"
import { WellnessService } from "../Services/Services"
import { getTranslationByKey } from "../global"
import { ApplyFilters, ResetFilters } from "./Buttons"
import Select from "react-select";
import { BackButton } from "./BackButton"
import { ManagePage } from "./ManagePage"
import { InternalBackButton } from "./InternalBackButton";
import { LoadingComponentWeb } from "./LoadingComponent";
export function WellnessLabSingleModule(props) {
    const [currentWeekCourses, setCurrentWeekCourses] = useState([])
    const [currFirstdayWeek, setCurrFirstdayWeek] = useState(new Date())
    const [currLastdayWeek, setCurrLastdayWeek] = useState(new Date())
    const [weekDates, setWeekDates] = useState([])
    const [loading, setLoading] = useState(true)
    const [filtersModal, showFilters] = useState(false)
    const [freeSeats, setFreeSeats] = useState(false)
    const [text, setText] = useState('')
    const [trainers, setTrainers] = useState([])
    const [locations, setLocations] = useState([])
    const [selectedLocation, setSelectedLocation] = useState(null)
    const [selectedTrainer, setSelectedTrainer] = useState(null)
    const [useFilters, setUseFilters] = useState(false)
    const [courseView, setCourseView] = useState(false)
    const [selectedDay, setSelectedDay] = useState(false)
    const [courseDetailView, setCourseDetailView] = useState(false)
    const [mobileCourseDetailView, setMobileCourseDetailView] = useState(false)
    let ws = new WellnessService()


    useEffect(() => {

        let curr = new Date(); // get current date
        let tmp = getMondayOfCurrentWeek(curr)
        let firstday = new Date(tmp.setDate(tmp.getDate() - tmp.getDay() + 1));
        let lastday = new Date(tmp.setDate(tmp.getDate() - tmp.getDay() + 7));
        setWeeksDates(tmp)
        setCurrFirstdayWeek(firstday)
        setCurrLastdayWeek(lastday)
        getAllCourses(firstday, lastday, false)
        ws.getTrainers().then((data) => {
            dataToSelectOptions(data, 'trainers')
        }).catch((err) => {
            setLoadingFunc(false)
        })

        ws.getGyms().then((data) => {
            dataToSelectOptions(data, 'locations')
            setLoadingFunc(false)
        }).catch((err) => {
            setLoadingFunc(false)
        })
    }, [])

    // Get the monday date of current week
    function getMondayOfCurrentWeek(d) {

        const date = new Date(d);
        const day = date.getDay(); // Sunday - Saturday : 0 - 6

        //  Day of month - day of week (-6 if Sunday), otherwise +1
        const diff = date.getDate() - day + (day === 0 ? -6 : 1);

        return new Date(date.setDate(diff));
    }

    //separate courses by week
    const createWeekCourses = (data) => {
        if (data.length > 0) {
            let currDate = data[0].date
            let weekday = []
            let weekCourses = []
            data.map((course, index) => {
                if (course.date === currDate)
                    weekday.push(course)
                else {
                    //set first week course array and go next
                    weekCourses.push(weekday)
                    weekday = []
                    currDate = course.date
                    weekday.push(course)
                }


                if (selectedDay && course.id == selectedDay.courses[selectedDay.index].id) {
                    selectedDay.courses[selectedDay.index] = course
                    setSelectedDay({ ...selectedDay })
                }
            })

            weekCourses.push(weekday)

            //check for empty days
            for (let index = 0; index < 5; index++)
                if (!weekCourses[index] || new Date(weekCourses[index][0].date).getDay() != index + 1)
                    weekCourses.splice(index, 0, [])

            setCurrentWeekCourses([...weekCourses])




        } else {
            setCurrentWeekCourses([])

        }

    }
    const getAllCourses = (firstday, lastday, filters) => {
        return new Promise(async (resolve, reject) => {
            let options = {
                "name": !filters ? null : text,
                "from_date": firstday,
                "to_date": lastday,
                "gym_id": !filters || !selectedLocation ? null : selectedLocation.value,
                "trainer_id": !filters || !selectedTrainer ? null : selectedTrainer.value,
                "only_available": !filters ? null : freeSeats
            }
            ws.getFilteredSchedule(options).then((data) => {
                createWeekCourses(data)
                resolve('')
            })
        })
    }

    const dataToSelectOptions = (data, type) => {
        let list = []

        switch (type) {
            case 'trainers':
                data.map((l) => {
                    list.push({ 'value': l.id, 'label': l.first_name + l.last_name })
                })
                setTrainers(list)
                break;
            case 'locations':
                data.map((l) => {
                    list.push({ 'value': l.id, 'label': l.name })
                })
                setLocations(list)
                break;
            default:
                break;
        }
    }
    const getData = (date, char) => {

        let new_date = new Date(date)
        let toStringDate = ""
        let lang = navigator.language
        if (localStorage.getItem("selectedLanguage"))
            lang = localStorage.getItem("selectedLanguage")
        let day = new_date.toLocaleDateString(lang.split('-')[0], { weekday: 'long' })
        if (char === '-')
            toStringDate = new_date.getFullYear().toString() + char + ("0" + (new_date.getMonth() + 1)).slice(-2).toString() + char + ("0" + new_date.getDate()).slice(-2).toString()
        else
            toStringDate = day + ', ' + ("0" + new_date.getDate()).slice(-2).toString() + char + ("0" + (new_date.getMonth() + 1)).slice(-2).toString() + char + new_date.getFullYear().toString()

        return toStringDate
    }

    const getHour = (string) => {
        return string.slice(0, 5)
    }

    const isTodayDate = (date) => {
        let d = new Date(date)
        let today = new Date()
        return d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()
    }
    //Set dates to get title when i have not courses for a day
    const setWeeksDates = (curr) => {
        let tmp = []
        for (let i = 1; i < 6; i++) {
            tmp.push(new Date(curr.setDate(curr.getDate() - curr.getDay() + i)))
        }
        setWeekDates(tmp)

    }
    const setLoadingFunc = async (val) => {
        setLoading(val)
    }
    //end utility functions

    const previousWeek = () => {
        setLoadingFunc(true)
        let curr = new Date(currFirstdayWeek)
        let tmp = new Date(currFirstdayWeek)
        let tmp2 = new Date(currFirstdayWeek)
        let firstday = new Date(curr.setDate(curr.getDate() - 7));
        let lastday = new Date(tmp.setDate(tmp.getDate() - 1));
        let lastday2 = new Date(tmp2.setDate(tmp2.getDate() - 1));
        setWeeksDates(lastday)
        setCurrFirstdayWeek(firstday)
        setCurrLastdayWeek(lastday2)
        getAllCourses(firstday, lastday2, useFilters).then(() => {
            setLoadingFunc(false)
        })
    }

    const nextWeek = () => {
        setLoadingFunc(true)
        let curr = new Date(currLastdayWeek)
        let firstday = new Date(curr.setDate(curr.getDate() + 1));
        let lastday = new Date(curr.setDate(curr.getDate() + 6));
        setWeeksDates(curr)
        setCurrFirstdayWeek(firstday)
        setCurrLastdayWeek(lastday)
        getAllCourses(firstday, lastday, useFilters).then(() => {
            setLoadingFunc(false)
        })
    }

    const handleChange = (e, type) => {
        switch (type) {
            case 'trainers':
                setSelectedTrainer(e)
                break;
            case 'locations':
                setSelectedLocation(e)
                break;
            default:
                break;
        }
    }

    const applyFilters = () => {

        setLoadingFunc(true).then(() => {
            setUseFilters(true)
            getAllCourses(currFirstdayWeek, currLastdayWeek, true).then(() => {
                setLoadingFunc(false)
            })
        })
    }
    const resetFilters = () => {

        setUseFilters(false)
        setSelectedTrainer(null)
        setSelectedLocation(null)
        setText('')
        setFreeSeats(false)

        setLoadingFunc(true).then(() => {
            setUseFilters(false)
            getAllCourses(currFirstdayWeek, currLastdayWeek, false).then(() => {
                showFilters(false)
                setLoadingFunc(false)
            })
        })
    }
    const openCourse = (day_courses, index) => {
        setCourseView(true)
        setMobileCourseDetailView(true)
        setSelectedDay({ index: index, courses: day_courses })
    }

    const openCourseDetail = (day_courses, index) => {
        setCourseView(true)
        setMobileCourseDetailView(true)
        setCourseDetailView(true)
        setSelectedDay({ index: index, courses: day_courses })
    }

    const goBack = () => {
        setCourseDetailView(false)
        //setSelectedDay(null)
    }

    const goBackDetail = () => {
        setMobileCourseDetailView(false)
    }

    const reload = () => {
        setLoadingFunc(true)
        getAllCourses(currFirstdayWeek, currLastdayWeek, false).then(() => {

            showFilters(false)
            setLoadingFunc(false)
        })
    }

    let day_cards = ''
    if (currentWeekCourses)
        day_cards = currentWeekCourses.map((courses, index) =>
            courses[index] && courses.length > 0 ?
                <div>
                    <div className={isTodayDate(courses[index].date) ? "today_card" : "day_card"}>
                        <div className="day_title">{getData(courses[index].date, '/')}</div>
                        <div className="card_day_container">
                            {courses.map((c, i) =>

                                <React.Fragment>
                                    <div key={index} className={c.is_current_trainer_course ? (c.course.type.id == 2 ? "my_day_service_course flex pointer" : "my_day_course flex pointer") : "day_course flex pointer"} onClick={() => openCourse(courses, i)}>
                                        <div Style="text-align: -webkit-left;">
                                            <div className="day_course_name">{getTranslationByKey(c.course.name)}</div>
                                            <div className="day_course_hour">{getHour(c.time.from)} - {getHour(c.time.to)}, {c.trainer.first_name} {c.trainer.last_name}</div>
                                            <div className="day_course_gym">{c.gym.name}</div>
                                            {props.user.modules.wellness_lab.is_admin || (c.is_bookable && props.user.modules.wellness_lab.is_trainer) ?
                                                <div className="flex">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill mr-5" viewBox="0 0 16 16">
                                                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                                                    </svg>
                                                    <div className="day_course_gym mr-5">{c.participants.length} / {c.course.max_participants}</div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" Style={"margin-left: 10px;"} fill="black" class="bi bi-clock mr-5" viewBox="0 0 16 16">
                                                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                                    </svg>
                                                    <div className="day_course_gym">{c.waiting_list.length}</div>
                                                </div>
                                                : ''}
                                        </div>
                                        <div Style={"margin-left: auto"}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                            </svg>
                                        </div>
                                    </div>
                                </React.Fragment>

                            )
                            }
                        </div>
                    </div>
                </div>
                :
                <div className="day_card">
                    <div className="day_title">{getData(weekDates[index], '/')}</div>
                    <div className="card_day_container">
                        <div Style={"max-width: 305px; padding: 20px;"}>
                            <div className="bold" Style="white-space: break-spaces;">{getTranslationByKey('WELLNESS.COURSES.NOCLASSES')}</div>
                        </div>
                    </div>
                </div>
        )
    let selectedCardContainer = ''
    if (selectedDay && selectedDay.courses && selectedDay.index >= 0)
        selectedCardContainer = <div>
            <div className="day_card" Style={"overflow: hidden;"}>
                <div className="day_title">{getData(selectedDay.courses[selectedDay.index].date, '/')}</div>
                <div className="card_day_container ">
                    {selectedDay.courses.map((c, index) =>

                        <React.Fragment>
                            <div key={index} className={c.is_current_trainer_course ? (c.course.type.id == 2 ? "my_day_service_course flex pointer" : "my_day_course flex pointer") : "day_course flex pointer"} onClick={() => openCourseDetail(selectedDay.courses, index)} >
                                <div>
                                    <div className="day_course_name">{getTranslationByKey(c.course.name)}</div>
                                    <div className="day_course_hour">{getHour(c.time.from)} - {getHour(c.time.to)}, {c.trainer.first_name} {c.trainer.last_name}</div>
                                    <div className="day_course_gym">{c.gym.name}</div>
                                    {props.user.modules.wellness_lab.is_admin || (c.is_bookable && props.user.modules.wellness_lab.is_trainer) ?
                                        <div className="flex">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill mr-5" viewBox="0 0 16 16">
                                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                                            </svg>
                                            <div className="day_course_gym mr-5">{c.participants.length} / {c.course.max_participants}</div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" Style={"margin-left: 10px;"} fill="black" class="bi bi-clock mr-5" viewBox="0 0 16 16">
                                                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                            </svg>
                                            <div className="day_course_gym">{c.waiting_list.length}</div>
                                        </div>
                                        : ''}
                                </div>
                                <div Style={"margin-left: auto"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                            </div>
                        </React.Fragment>)
                    }
                </div>
            </div>
        </div>

    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: "#fbfbfb",
            border: 0,
            borderColor: state.isSelected ? null : null,
            boxShadow: state.isFocused ? null : null,

        }),
        option: (base, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...base,
                background: "#fbfbfb",
                color: "#000000",
                "font-size": '14px',
                padding: '5px 0',
                '&:hover': {
                    background: "#d6d6d6",
                }
            };
        }
    };

    const goBackButton = () => {
        props.select('offices', props.selected_office)
        props.setHidden(false)
    }

    const handleBackMobile = () => {
        goBackDetail()
    }


    const [scrollX, setScrollX] = useState({
        side: ""
    });
    const [scrollLeft, setScollLeft] = useState(0)
    const scrollElement = useRef(null);
    const handleScroll = (data) => {
        setScrollX(prev => ({ ...prev, side: data.side }));
    }
    useEffect(() => {
        if (scrollElement.current) {

            if (scrollX.side === "right") {
                scrollElement.current.scrollLeft = scrollElement.current.scrollLeft + 500;
            } else {
                scrollElement.current.scrollLeft = scrollElement.current.scrollLeft - 500;
            }
            setScollLeft(scrollElement.current.scrollLeft)
        }
    }, [scrollX]);

    return (loading ? <LoadingComponentWeb /> :
        <div className="wellness_container">
            <div Style={courseView ? 'margin-top: 10px;' : ''}>

                {!courseView ?
                    <React.Fragment>
                        <div className="title_container title_container_wellness calendar_title">
                            <div className="flex wellness_admin_filters_container" Style={'width:100%'}>
                                <div className='arrows_container' Style={'width:calc(100% - 50px)'}>
                                    <button Style={'margin-right:auto; color:black'} className="arrow_button" onClick={previousWeek}><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-left " viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                    </svg> <div Style={'color:black'}>{getTranslationByKey('WELLNESS.WEEK.PREVIOUS')}</div></button>

                                    <button className="arrow_button" onClick={nextWeek} Style={'color:black'}> <div Style={'color:black'}>{getTranslationByKey('WELLNESS.WEEK.NEXT')}</div><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-right " viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg></button>
                                </div>
                                <div className="filters flex ">
                                    <svg onClick={() => showFilters(true)} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class=" pointer ml-3 bi bi-filter" viewBox="0 0 16 16">
                                        <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                                    </svg>
                                </div>
                            </div>

                        </div>

                        {currentWeekCourses && currentWeekCourses.length > 0 ?
                            <div className="flex vertical_center">
                                {window.innerWidth < 1000 ?
                                    '' :
                                    scrollLeft > 0 ?
                                        <div Style="margin-right: 10px;" className="pointer" onClick={() => handleScroll({ side: "left" })}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
                                            </svg>
                                        </div>
                                        :
                                        <div Style="min-width: 40px;"></div>

                                }
                                <div className="card_days_container flex" Style={'overflow:hidden;'} >
                                    <div className="flex card_days_overflow" ref={scrollElement}>

                                        {day_cards}

                                    </div>
                                    {filtersModal ?
                                        <div>
                                            <div className="filters_container_admin">
                                                <div className="close" >
                                                    <svg onClick={() => showFilters(false)} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                    </svg>
                                                </div>
                                                <div className="filters_list">
                                                    <div className="filters_body">
                                                        <div className="filter_title" Style={"margin-top: 0px;"}>
                                                            {getTranslationByKey('WELLNESS.COURSES.FILTER.NAME')}</div>
                                                        <div className="filters_card">
                                                            <input className="filter_input_name" placeholder={getTranslationByKey('WELLNESS.COURSES.FILTER.COURSENAME')} type='text' value={text} onChange={(e) => setText(e.target.value)} />
                                                            <hr className="filter_separator" />
                                                            <div className="filter_text_fixed flex" type='text' >
                                                                {getTranslationByKey('WELLNESS.COURSES.FILTER.FREESEATS')}
                                                                <input checked={freeSeats} type="checkbox" className="checkbox" id="chk" onChange={() => setFreeSeats(!freeSeats)} />
                                                                <label className="filter_label" htmlFor="chk">
                                                                    <div className="filter_ball"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="filter_title mt-3">
                                                            {getTranslationByKey('WELLNESS.COURSES.FILTER.TRAINER')}</div>
                                                        <div className="filters_card_s">
                                                            <Select
                                                                value={selectedTrainer}
                                                                onChange={(e) => handleChange(e, 'trainers')}
                                                                options={trainers}
                                                                className="filter_input pt-1"
                                                                placeholder={selectedTrainer}
                                                                styles={customStyles}
                                                            />
                                                        </div>
                                                        <div className="filter_title mt-3">
                                                            {getTranslationByKey('WELLNESS.COURSES.FILTER.LOCATION')}</div>
                                                        <div className="filters_card_s">
                                                            <Select
                                                                value={selectedLocation}
                                                                onChange={(e) => handleChange(e, 'locations')}
                                                                options={locations}
                                                                className="filter_input pt-1"
                                                                placeholder={selectedLocation}
                                                                styles={customStyles}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="filters_buttons_container">
                                                        <ApplyFilters onClick={() => applyFilters()} />
                                                        <ResetFilters onClick={() => resetFilters()} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : ''}
                                </div>

                                {window.innerWidth < 1000 ? ''
                                    :
                                    (scrollElement.current && scrollElement.current.scrollLeft > 600) ?
                                        <div Style="min-width: 40px;"></div>
                                        :
                                        <div Style="margin-right: 10px;" className="pointer" onClick={() => handleScroll({ side: "right" })}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                                            </svg>
                                        </div>}
                            </div> :
                            <div className="card_day_container">
                                <div Style={"padding: 20px; text-align: center; font-family: 'GucciSans-Bold'; margin-top: 30vh;"}>{getTranslationByKey('WELLNESS.COURSES.EMPTY')}</div>
                            </div>

                        }
                    </React.Fragment>
                    :
                    <React.Fragment>
                        {courseView ?
                            (window.innerWidth > 1000 ? <InternalBackButton name={'back_button_component_dx pointer flex'} key='selected_book' backButtonFunction={() => setCourseView(false)} index={210} /> : <BackButton backButtonFunction={() => setCourseView(false)} index={210} />) : ''}
                        <div className="card_days_container flex" Style={'overflow:hidden;'}>
                            <div className="flex" Style={window.innerWidth > 1000 ? 'overflow:auto;  padding-bottom: 10px; width: 100%;' : 'overflow:auto;  padding-bottom: 10px; width: 100%; place-content: center;'}>
                                {window.innerWidth > 1000 ? '' : (courseDetailView ? <BackButton backButtonFunction={goBack} index={210} /> : '')}

                                {window.innerWidth < 1000 ?
                                    <React.Fragment>
                                        {!mobileCourseDetailView ? selectedCardContainer :
                                            <ManagePage setLoading={setLoading} key={selectedDay.courses[selectedDay.index].id} course={selectedDay.courses[selectedDay.index]} user={props.user} reload={reload} />
                                        }
                                    </React.Fragment> :
                                    <React.Fragment>
                                        {selectedCardContainer}
                                        <ManagePage setLoading={setLoading} key={selectedDay.courses[selectedDay.index].id} course={selectedDay.courses[selectedDay.index]} user={props.user} reload={reload} />
                                    </React.Fragment>}

                            </div>
                        </div>

                    </React.Fragment>
                }
            </div>
        </div>
    )
}