import React, { useEffect, useState } from "react"
import { OpportunitiesService } from "../Services/Services"
import { OpenQuestions, RadioQuestions, RatingQuestions } from "./OpportunitiesQuestions";
import DatePicker from "react-datepicker";
import { UseMessage } from '../View/Alert';
import { customStyles, customStylesMobileFeedbackForm, getTranslationByKey } from '../global';
import Select from "react-select";
import Markdown from 'markdown-to-jsx'


export function OpportunitiesFeedbackForm(props) {


    const OS = new OpportunitiesService
    const [form, setForm] = React.useState([])
    const [selectedDate, setSelectedDate] = React.useState('')
    const [opportunities, setOpportunities] = React.useState([])
    const [opportunitiesOptions, setOpportunitiesOptions] = React.useState([])
    const [selectedOpportunity, setSelectedOpportunity] = React.useState('')
    const [types, setTypes] = React.useState([])
    const [selectedType, setSelectedType] = React.useState('')
    const [answer, setAnswer] = React.useState([])

    useEffect(() => {
        OS.getFeedbackForm().then((data) => {
            setForm(data.questions)
            let tmp = []
            data.types.map((o) => {
                tmp.push({ label: o.name, value: o.id })
            })
            setTypes([...tmp])
            setOpportunitiesOptions([...data.opportunities])
        })
    }, [])


    useEffect(() => {
        setSelectedOpportunity('')
        if (selectedType && selectedType.label && selectedType.label !== '') {
            let tmp_opportunities = []
            if (opportunitiesOptions)
                opportunitiesOptions.map((l) => {
                    if (l.type_id == selectedType.value)
                        tmp_opportunities.push({
                            label: l.title,
                            value: l.id
                        })
                })
            setOpportunities([...tmp_opportunities])
        }
    }, [selectedType])


    const handleCheckbox = (e, id, answer) => {
        let f = form
        f.map((elem) => {
            if (elem.question_id == id) {
                elem.answer = answer
                elem.answer_id = e.target.id
            }
        })
        setAnswer([...f])
    }

    const handleText = (answer, id) => {
        let f = form
        f.map((elem) => {
            if (elem.id == id) {
                elem.answer = answer.target.value
            }
        })
        setAnswer([...f])
    }

    const handleRating = (e, id) => {
        let f = form
        f.map((elem) => {
            if (elem.id == id) {
                elem.answer = e
            }
        })
        setAnswer([...f])
    }

    const handleDate = (id, e) => {
        let f = form
        setSelectedDate(e)
        f.map((elem) => {
            if (elem.id == id) {
                elem.answer = e
            }
        })
        setAnswer([...f])
    }

    const getData = (date) => {
        let new_date = new Date(date)
        let toStringDate = ""
        toStringDate = new_date.getFullYear().toString() + '-' + ("0" + (new_date.getMonth() + 1)).slice(-2).toString() + '-' + ("0" + new_date.getDate()).slice(-2).toString()
        return toStringDate
    }

    const saveFeedback = () => {
        let tmp = []
        let ret = true
        if (selectedType.value && selectedType.value === '')
            ret = false
        if (selectedOpportunity.value && selectedOpportunity.value === '')
            ret = false
        if (answer && answer.length > 0)
            answer.map((a) => {
                if (a.answer)
                    if (a.type === "likert_scale")
                        tmp.push({ "question_id": a.id, "likert_response": a.answer })
                    else if (a.type === "date")
                        tmp.push({ "question_id": a.id, "date_response": getData(a.answer) })
                    else
                        tmp.push({ "question_id": a.id, "text_response": a.answer })
                else
                    if (a.required)
                        ret = false
            })
        else
            ret = false


        if (!ret) return UseMessage(getTranslationByKey('OPPORTUNITIES.LABLE.WARNING.ALLFIELDSREQUIRED'))


        let body = {
            "opportunity_id": selectedOpportunity.value, // number | undefined -> Required for experiences
            "type_id": selectedType.value, // number | undefined -> Required for experiences
            "answers": tmp
        }

        /*
        */

        OS.saveFeedback(body).then((data) => {
            props.select('opportunities', props.user.modules.opportunities)
        })
    }


    return (
        <React.Fragment>
            <div className="opportunities_content opportunities_feedback_content">
                <div className="opportunities_main_image_title"></div>
                <div Style="text-align: left;width: 91%;">
                    <div Style="text-align: -webkit-left; margin-bottom: 5px; line-height: 6px;"><Markdown>{getTranslationByKey('OPPORTUNITIES.LABLE.ANONYMOUSFEEDBACK')}</Markdown></div>
                    <div className={window.innerWidth > 1000 ? "flex" : ''}>
                        <div className="opportunities_feedbackform_card" Style={window.innerWidth > 1000 ? "width: 50%; text-align: -webkit-left;" : "width: 100%; text-align: -webkit-left;"}>
                            <div className="bold opportunities_label_feedbackform">{getTranslationByKey('OPPORTUNITIES.FEEDBACK.QUESTION.TYPE')} *</div>
                            <Select
                                value={selectedType}
                                isClearable={true}
                                onChange={(e) => setSelectedType(e)}
                                options={types ?? []}
                                className="opportunities_select"
                                styles={customStylesMobileFeedbackForm}
                            />
                        </div>
                        {selectedType && selectedType !== '' ?
                            <div className="opportunities_feedbackform_card" Style={window.innerWidth > 1000 ? "width: 50%; text-align: -webkit-left; margin-left: 10px;" : "width: 100%; text-align: -webkit-left"}>
                                <div className="bold opportunities_label_feedbackform">{getTranslationByKey('OPPORTUNITIES.FEEDBACK.QUESTION.OPPORTUNITY')} *</div>
                                <Select
                                    value={selectedOpportunity}
                                    isClearable={true}
                                    onChange={(e) => setSelectedOpportunity(e)}
                                    options={opportunities ?? []}
                                    className="opportunities_select"
                                    styles={customStylesMobileFeedbackForm}
                                />
                            </div>
                            : ''}
                    </div>

                    {form && form.length > 0 ?
                        form.map((q, index) =>
                            <React.Fragment>
                                <div className="opportunities_feedbackform_card">
                                    {q.type === 'open' ?
                                        <OpenQuestions q={q} handleText={handleText} style={"width: 100%; margin-top: 10px;"} />
                                        : (q.type === 'single_choice' ? <RadioQuestions q={q} index={index} handleCheckbox={handleCheckbox} />
                                            : (q.type === 'likert_scale' ?
                                                <RatingQuestions q={q} handleRating={handleRating} />
                                                : (q.type === 'date' ?
                                                    <div className="opportunities_datepicker_container opportunities_question_row">
                                                        <div className="bold opportunities_label_feedbackform">{getTranslationByKey(q.question)} *</div>
                                                        <DatePicker
                                                            showIcon
                                                            className="meeting_rooms_datepicker opportunities_datepicker opportunities_datepicker_feedback"
                                                            selected={selectedDate}
                                                            minDate={new Date()}
                                                            onChange={(date) => { handleDate(q.id, date) }}
                                                            placeholderText="gg/mm/yyyy"
                                                            popperPlacement="bottom-center"
                                                            dateFormat={"dd/MM/yyyy"}
                                                        />
                                                    </div>
                                                    : '')))}
                                </div>
                            </React.Fragment>
                        )
                        : ''
                    }
                </div>
            </div>
            <div Style="margin-top: 20px;">
                <div className="opportunities_action_button bold pointer flex" onClick={() => saveFeedback()}>
                    <div className="bold">
                        {getTranslationByKey('GENERIC.ALERT.SAVE')}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}