import React, { useEffect, useRef } from "react"
import { OppotunitiesDetail } from "./OpportunitiesDetail"
import { InternalBackButton } from "./InternalBackButton"
import { OpportunitiesCreate } from "./OpportunitiesCreate"
import { OpportunitiesService } from "../Services/Services"
import { Container, Dropdown, Nav, Navbar } from "react-bootstrap"
import { OpportunitiesSection } from "./OpportunitiesSection"
import { getTranslationByKey } from "../global"
import { OpportunitiesCard } from "./OpportunitiesCard"
import { OpportunitiesUpdate } from "./OpportunityUpdate"
import DocumentPreview from "./DocumentPreview"
import { PDFDocument } from "./PdfDocument"
import { BackButton } from "./BackButton"
import { LoadingComponent } from "./LoadingComponent"

export function Opportunities(props) {

    const OS = new OpportunitiesService
    const [isLoading, setLoading] = React.useState(true)
    const [isLoadingOpportunities, setLoadingOpportunities] = React.useState(false)
    const [list, setList] = React.useState([])
    const [detailView, setDetailView] = React.useState(false)
    const [createView, setCreateView] = React.useState(false)
    const [updateView, setUpdateView] = React.useState(false)
    const [isDuplication, setDuplication] = React.useState(false)
    const [detailType, setDetailType] = React.useState('')
    const [selectedOpportunity, setSelectedOpportunity] = React.useState(null)
    const [selectedMenu, setSelectedMenu] = React.useState('available')
    const [prevSelectedMenu, setPrevSelectedMenu] = React.useState('')
    const [userView, setUserView] = React.useState(true)
    const [projectOpportunitiesView, setProjectOpportunitiesView] = React.useState(true)
    const [reloadAll, setReloadAll] = React.useState(false)
    const [viewType, setViewType] = React.useState('fieldView')
    const [viewAll, setViewAll] = React.useState(false)
    const [selectedType, setSelectedType] = React.useState(null)
    const [itemsList, setItemsList] = React.useState(null)

    useEffect(() => {

        handleSelectedMenu(selectedMenu)
    }, [userView, reloadAll])

    const handleUserView = (bool) => {
        setLoadingOpportunities(true)
        setUserView(bool)
    }

    const handleSelectedMenu = (menu) => {

        if (userView) {
            switch (menu) {
                case 'available':

                    OS.getAvailableOpportunity().then((data) => {
                        setList([...data.items])
                        handleItemsList(data.items)
                    }).finally(() => {
                        setSelectedMenu(menu)
                        setLoadingOpportunities(false)
                        setLoading(false)

                    })
                    break;
                case 'booked':
                    setLoadingOpportunities(true)
                    window.scrollTo(0, 0, 'smooth')
                    OS.getBookedOpportunity().then((data) => {
                        setList([...data.opportunities])
                    }).finally(() => {
                        setSelectedMenu(menu)
                        setLoadingOpportunities(false)
                        setLoading(false)
                    })
                    break;
                case 'wishlist':
                    setLoadingOpportunities(true)
                    window.scrollTo(0, 0, 'smooth')
                    OS.getWishlistOpportunity().then((data) => {
                        setList([...data.opportunities])
                    }).finally(() => {
                        setSelectedMenu(menu)
                        setLoadingOpportunities(false)
                        setLoading(false)
                    })
                    break;
                default:
                    break;
            }
        }
        else {
            OS.getProjectManagerOpportunities().then((data) => {
                setList([...data.opportunities])
            }).finally(() => {
                setSelectedMenu(menu)
                setTimeout(() => {

                    setLoadingOpportunities(false)
                    setLoading(false)
                }, 200);
            })
        }

    }

    const handleItemsList = (list) => {
        let items = ''
        if (list && list.length > 0)
            items = list.map((l, index) => <Dropdown.Item onClick={(e) => goToSection(e, index)}>{l.type.name}</Dropdown.Item>)
        setItemsList(items)
    }

    useEffect(() => {
        window.scrollTo(0, 0, 'smooth')
        if (!viewAll)
            reloadWishlist()
    }, [detailView, viewAll])

    const handleOpportunityDetail = (opportunity) => {
        setSelectedOpportunity(opportunity)
        setDetailView(true)
        if (opportunity.type && opportunity.type.id == 2)
            setDetailType('experience')
        else
            setDetailType('project')

    }
    const reload = () => {
        setReloadAll(!reloadAll)
    }

    const reloadWishlist = () => {
        if (userView) {
            switch (selectedMenu) {
                case 'available':
                    OS.getAvailableOpportunity().then((data) => {
                        setList([...data.items])
                    }).finally(() => {
                        setSelectedMenu(selectedMenu)
                    })
                    break;
                case 'booked':
                    OS.getBookedOpportunity().then((data) => {
                        setList([...data.opportunities])
                    }).finally(() => {
                        setSelectedMenu(selectedMenu)
                    })
                    break;
                case 'wishlist':
                    OS.getWishlistOpportunity().then((data) => {
                        setList([...data.opportunities])
                    }).finally(() => {
                        setSelectedMenu(selectedMenu)
                    })
                    break;
                default:
                    break;
            }
        }
        else {
            OS.getProjectManagerOpportunities().then((data) => {
                setList([...data.opportunities])
            }).finally(() => {
                setSelectedMenu(selectedMenu)
            })
        }
    }

    const scrollRef = useRef(null);

    const goToSection = (e, sectionId) => {
        //e.stopPropagation()
        if (window.innerWidth > 1000)

            document.body.scrollTop = document.documentElement.scrollTop = sectionId * 270;
        else {
            if (scrollRef.current) {
                if (sectionId > 0) {
                    scrollRef.current.scrollTop = scrollRef.current.scrollTop + 100;
                } else {
                    scrollRef.current.scrollTop = scrollRef.current.scrollTop - 100;

                }
                //setScollLeft(scrollRef.current.scrollLeft)
            }
        }
    }

    const createBack = () => {
        if (viewType === 'fieldView')
            setCreateView(false)
        else {
            setCreateView(true)
            setViewType('fieldView')
        }
    }

    const updateBack = () => {
        if (viewType === 'fieldView')
            setUpdateView(false)
        else {
            setUpdateView(true)
            setViewType('fieldView')
        }
    }

    const handleDuplicateView = () => {
        setDuplication(true)
        setUpdateView(true)
    }

    const handleViewAll = (type) => {
        setViewAll(true)
        setLoading(true)
        setPrevSelectedMenu(selectedMenu)
        handleSelectedMenu('view_all')
        setSelectedType(type.id)
        OS.getListByType(type.id).then((data) => {
            setList([...data.opportunities])
            setLoading(false)
        })
        /*.finally(() => {
            setSelectedMenu(menu)
        })*/
    }
    const handleBackViewAll = () => {
        setViewAll(false)
        setSelectedType(null)
        handleSelectedMenu(prevSelectedMenu)

        setReloadAll(!reloadAll)
    }

    const handleRemoveOpportunity = () => {
        setDetailView(false)
        setReloadAll(!reloadAll)
    }

    const add = (e, card) => {
        e.stopPropagation()
        OS.addToWishlist(card.id).then(() => {
            if (!viewAll)
                reloadWishlist()
            else
                reloadViewAll(selectedType)
        })
    }

    const remove = (e, card) => {
        e.stopPropagation()
        OS.removeFromWishlist(card.id).then(() => {
            if (!viewAll)
                reloadWishlist()
            else
                reloadViewAll(selectedType)
        })
    }

    const reloadViewAll = (type) => {
        setViewAll(true)
        setLoading(true)
        setPrevSelectedMenu(selectedMenu)
        handleSelectedMenu('view_all')
        setSelectedType(type)
        OS.getListByType(type).then((data) => {
            setList([...data.opportunities])
            setLoading(false)
        })
    }

    const handleBackDetailView = () => {
        if (viewAll)
            reloadViewAll(selectedType)
        setDetailView(false)
    }

    let opportunities = ''
    if (list && list.length > 0 && viewAll && userView) {
        opportunities = list.map((l) => <OpportunitiesCard userView={userView} key={l.id} reload={reload} reloadWishlist={reloadWishlist} card={l} handleOpportunityDetail={handleOpportunityDetail} tag={false} handleViewAll={handleViewAll} viewAll={viewAll} selectedType={selectedType} add={add} remove={remove} />)
    }
    else if (list && list.length > 0 && selectedMenu && selectedMenu === 'available' && userView) {
        opportunities = list.map((l, i) => <OpportunitiesSection userView={userView} key={i} has_more={l.has_more} reloadWishlist={reloadWishlist} title={l.type} list={l.opportunities} handleOpportunityDetail={handleOpportunityDetail} reload={reload} handleViewAll={handleViewAll} viewAll={viewAll} selectedType={selectedType} add={add} remove={remove} />)
    }
    else if (list && list.length > 0 && (selectedMenu !== 'available' || !userView)) {
        opportunities = list.map((l) => <OpportunitiesCard userView={userView} reload={reload} card={l} reloadWishlist={reloadWishlist} handleOpportunityDetail={handleOpportunityDetail} tag={true} handleViewAll={handleViewAll} viewAll={true} selectedType={selectedType} add={add} remove={remove} />)
    }

    return (
        <div className="opportunities_global_container">
            {isLoading ?
                <div className='loader_ellipsis loader_ellipsis_web' >
                    <div class="lds-ellipsis">
                        <div Style={"background: #000000"}></div>
                        <div Style={"background: #000000"}></div>
                        <div Style={"background: #000000"}></div>
                        <div Style={"background: #000000"}></div>
                    </div>
                </div>
                :
                createView ?
                    <React.Fragment>
                        {window.innerWidth > 1000 ?
                            <InternalBackButton name={'back_button_component_dx pointer flex'} key='selected_book' backButtonFunction={() => createBack()} index={99} />
                            :
                            ''
                        }
                        <div Style="padding: 30px;">
                            <OpportunitiesCreate user={props.user} setViewType={setViewType} viewType={viewType} setReloadAll={setReloadAll} reloadAll={reloadAll} setCreateView={setCreateView} />
                        </div>
                    </React.Fragment>
                    :
                    updateView ? <React.Fragment>
                        {window.innerWidth > 1000 ?
                            <InternalBackButton name={'back_button_component_dx pointer flex'} key='selected_book' backButtonFunction={() => updateBack()} index={99} />
                            : ''}
                        <div Style="padding: 30px;">
                            <OpportunitiesUpdate isDuplication={isDuplication} setDuplication={setDuplication} setReloadAll={setReloadAll} reloadAll={reloadAll} user={props.user} selectedOpportunity={selectedOpportunity} setViewType={setViewType} viewType={viewType} setUpdateView={setUpdateView} />
                        </div>
                    </React.Fragment>
                        :
                        detailView ?
                            <React.Fragment >
                                {window.innerWidth > 1000 ?
                                    <InternalBackButton name={'back_button_component_dx pointer flex'} key='selected_book' backButtonFunction={() => handleBackDetailView()} index={99} />
                                    : <BackButton key='generic_back' backButtonFunction={() => handleBackDetailView()} index={210} />}
                                <div className="opportunities_detail_padding">
                                    <OppotunitiesDetail handleDuplicateView={handleDuplicateView} userView={userView} handleRemoveOpportunity={handleRemoveOpportunity} selectedOpportunity={selectedOpportunity} detailType={detailType} setDetailView={setDetailView} setUpdateView={setUpdateView} />
                                </div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                {viewAll ?
                                    (window.innerWidth > 1000 ? <InternalBackButton name={'back_button_component_dx pointer flex'} key='selected_book' backButtonFunction={() => handleBackViewAll()} index={99} />
                                        : <BackButton key='generic_back' backButtonFunction={() => handleBackViewAll()} index={210} />)
                                    :
                                    window.innerWidth > 1000 ?
                                        <InternalBackButton name={'back_button_component_dx pointer flex'} key='selected_book' backButtonFunction={() => props.back('infopage')} index={99} />
                                        : <BackButton key='generic_back' backButtonFunction={() => props.back('infopage')} index={210} />}
                                <div className="opportunities_content">
                                    <div className={viewAll && selectedType == 1 ? " opportunities_main_image_title_project" :
                                        (viewAll && selectedType == 2 ? " opportunities_main_image_title_experience" :
                                            "opportunities_main_image_title")} >
                                    </div>

                                    <div className="flex" Style={"justify-content: right; width: 91%"} >
                                        {props.user.modules.opportunities.is_admin ?
                                            <div className="create_opportunities_button pointer" Style="margin-right: auto;" onClick={() => window.open(window.location.hostname === 'gucciforyou.gucci.com' ? "https://smartoffice.globalbit.gucci/backoffice" : "https://smartoffice.globalbit.gucci/staging_backoffice", "_blank")}>
                                                <div className="flex">
                                                    <div className="bold">{getTranslationByKey('OPPORTUNITIES.BUTTON.ADMINBACKOFFICE')}</div>
                                                    <div Style="margin-left: 5px; margin-top: -2px;">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5" />
                                                            <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''}
                                        {(props.user.modules.opportunities.is_admin || props.user.modules.opportunities.is_manager) && window.innerWidth > 1000 ? <div Style="text-align: -webkit-right;">
                                            <div className="create_opportunities_button pointer" onClick={() => setCreateView(true)}>
                                                <div className="flex">
                                                    <div className="bold">{getTranslationByKey('OPPORTUNITIES.BUTTON.CREATE')}</div>
                                                    <div Style="margin-left: 5px; margin-top: -1px;">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : ''}
                                    </div>
                                    {/*  <div className="opportunities_buttons_container">
                                    <div onClick={() => setSelectedButton('available')} className={selectedButton === 'available' ? "opportunities_buttons_selected bold" : "opportunities_buttons pointer "}>Available</div>
                                    <div onClick={() => setSelectedButton('applied')} className={selectedButton === 'applied' ? "opportunities_buttons_selected bold" : "opportunities_buttons pointer "}>Applied</div>
                                    <div className="wishlist_separator"></div>
                                    <div onClick={() => setSelectedButton('wishlist')} className={selectedButton === 'wishlist' ? "opportunities_buttons_selected bold" : "opportunities_buttons pointer "}>Wishlist</div>
                                </div>
*/}
                                    {!viewAll && props.user.modules.opportunities.is_manager && window.innerWidth > 1000 ?
                                        <div>
                                            <div className="feed_buttons_container">
                                                <div className={userView ? "feed_button_selected bold" : "feed_button pointer"} onClick={() => handleUserView(true)}>{getTranslationByKey('OPPORTUNITIES.LABLE.USER')}</div>
                                                <div className={!userView ? "feed_button_selected bold" : "feed_button pointer"} onClick={() => handleUserView(false)}>{getTranslationByKey('OPPORTUNITIES.LABLE.PM')}</div>
                                            </div>
                                        </div>
                                        : ''}

                                    {userView ?
                                        <>
                                            {!viewAll ?
                                                <div className="navbar_opportunities_cont">
                                                    <Navbar variant='bg-transparent'>
                                                        <Container style={{ width: 400 }}>
                                                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                                            <Navbar.Collapse id="basic-navbar-nav">
                                                                <Nav className="me-auto">
                                                                    <Dropdown Style={'position:relative'}>
                                                                        <Dropdown.Toggle id="dropdownMenuButtonAvailable" className={selectedMenu === 'available' ? "selectedMenu" : ''} onFocus={() => handleSelectedMenu('available')}>
                                                                            {getTranslationByKey('OPPORTUNITIES.LABLE.AVAILABLE')}
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu id="dropdownMenuButtonAvailable">
                                                                            {itemsList}
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                    <hr width="1" size="500" Style={"margin: 0;    height: 0px;    width: 20px;    transform: rotate(90deg);"} />
                                                                    <Nav.Link className={selectedMenu === 'booked' ? "selectedMenu" : ''} onClick={() => handleSelectedMenu('booked')}>{getTranslationByKey('OPPORTUNITIES.LABLE.BOOKED')}</Nav.Link>
                                                                    <hr width="1" size="500" Style={"margin: 0;    height: 0px;    width: 20px;    transform: rotate(90deg);"} />
                                                                    <Nav.Link className={selectedMenu === 'wishlist' ? "selectedMenu" : ''} onClick={() => handleSelectedMenu('wishlist')} >{getTranslationByKey('OPPORTUNITIES.LABLE.WISHLIST')}</Nav.Link>


                                                                </Nav>
                                                            </Navbar.Collapse>
                                                        </Container>
                                                    </Navbar>
                                                </div>
                                                : <br />}
                                            {isLoadingOpportunities ?
                                                <div className='loader_ellipsis loader_ellipsis_web' Style="padding-top: 10vh !important;">
                                                    <div class="lds-ellipsis">
                                                        <div Style={"background: #000000"}></div>
                                                        <div Style={"background: #000000"}></div>
                                                        <div Style={"background: #000000"}></div>
                                                        <div Style={"background: #000000"}></div>
                                                    </div>
                                                </div>
                                                :
                                                opportunities === '' ?
                                                    <div className="mt-20 bold">{getTranslationByKey('OPPORTUNITIES.NOTAVAILABLE')}</div> :
                                                    viewAll ?
                                                        <div className="opportunities_card_cont opportunities_card_cont_viewall"> {opportunities}</div> :
                                                        selectedMenu === 'available' ?

                                                            <div className="opportunity_mobile_content" ref={scrollRef}>
                                                                {opportunities}
                                                            </div>
                                                            :
                                                            <div className="opportunities_card_cont opportunities_card_cont_viewall">{opportunities}</div>}
                                        </> : <>
                                            {/*
                                            <Navbar variant='bg-transparent' className="opportunities_pm_navbar" >
                                                <Container style={{ width: 400 }}>
                                                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                                    <Navbar.Collapse id="basic-navbar-nav">
                                                        <Nav className="me-auto" Style="margin-right: 0px !important;">

                                                            <Nav.Link className={projectOpportunitiesView ? "selectedMenu" : ''} onClick={() => setProjectOpportunitiesView(true)}>{getTranslationByKey('OPPORTUNITIES.LABLE.YOUROPPORTUNITIES')}</Nav.Link>
                                                            <hr width="1" size="500" Style={"margin: 0;    height: 0px;    width: 20px;    transform: rotate(90deg);"} />
                                                            <Nav.Link className={!projectOpportunitiesView ? "selectedMenu" : ''} onClick={() => setProjectOpportunitiesView(false)} >{getTranslationByKey('OPPORTUNITIES.LABLE.OPPORTUNITIESGUIDE')}</Nav.Link>


                                                        </Nav>
                                                    </Navbar.Collapse>
                                                </Container>
                                            </Navbar>*/}

                                            {!projectOpportunitiesView ?
                                                <div Style="position: relative;left: -20px;">
                                                    <PDFDocument url={props.user.modules.opportunities.pm_guide} />
                                                </div>
                                                : (isLoadingOpportunities ?
                                                    <div className='loader_ellipsis loader_ellipsis_web' Style="padding-top: 10vh !important;">
                                                        <div class="lds-ellipsis">
                                                            <div Style={"background: #000000"}></div>
                                                            <div Style={"background: #000000"}></div>
                                                            <div Style={"background: #000000"}></div>
                                                            <div Style={"background: #000000"}></div>
                                                        </div>
                                                    </div>
                                                    : <div className="opportunities_card_cont opportunities_card_cont_mt">{opportunities}</div>)}

                                        </>
                                    }
                                </div>

                            </React.Fragment >
            }


        </div >
    )
}
