import React, { Component, useEffect, useState } from "react";
import Select from "react-select";
import * as ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // ES6
import "react-quill/dist/quill.core.css";
import { customStyles, customStylesDisabled, getTranslationByKey } from '../global';
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { remark } from "remark";
import remarkHtml from "remark-html";
import Markdown from "markdown-to-jsx";
import DatePicker from "react-datepicker";
import rehypeParse from "rehype-parse";
import rehypeRemark from "rehype-remark";
import remarkStringify from "remark-stringify";
import { OpportunitiesService } from "../Services/Services";
import { UseAlertMessage } from "./Alert";
import moment from 'moment'
import 'moment/locale/en-gb';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import MultiSelectComponent from "./MultiSelectComponent";
import ScrollContainer from 'react-indiana-drag-scroll'
import SelectComponent from "./SelectComponent";
import { Form } from "react-bootstrap";
import DisabledTextBox from "./DisabledTextBox";
import DateSlotCard from "./DateSlotCard";

export function OpportunitiesUpdate(props) {
    const [selectedType, setSelectedType] = useState("")
    const [selectedPM, setSelectedPM] = React.useState([])
    const [selectedDurationType, setSelectedDurationType] = useState("")
    const [slotList, setSlotList] = React.useState([]);
    const [opportunity, setOpportunity] = useState(null)
    const [description, setDescription] = useState('')
    const [typeOptions, setTypeOptions] = React.useState([])
    const [startSlot, setStartSlot] = React.useState('');
    const [endSlot, setEndSlot] = React.useState('');
    const [maxParticipants, setMaxParticipants] = React.useState('');
    const [optionsToAdd, setOptionToAdd] = React.useState('')
    const [view, setView] = React.useState('week');
    const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
    const [openDetailDialog, setOpenDetailDialog] = React.useState(false);
    const [openRecapDialog, setOpenRecapDialog] = React.useState(false);
    const [usersList, setUsersList] = React.useState([])
    const [slotID, setSlotID] = React.useState('');
    const [selectedLocation, setSelectedLocation] = React.useState('')
    const [locations, setLocations] = React.useState([])
    const [visibilities, setVisibilities] = React.useState([])
    const [selectedVisibilityList, setSelectedVisibilityList] = React.useState([])
    const oS = new OpportunitiesService()
    var typingTimer;                //timer identifier
    var doneTypingInterval = 1000;

    const Quill = ReactQuill.Quill
    const modules = {
        toolbar: [["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }, "link", "attachment"],]
    }
    const localizer = momentLocalizer(moment)


    var isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
    const markdownToHtml = (string) => {
        let tmp = isHTML(string)
        if (tmp)
            return string
        else {
            const file = remark().use(remarkHtml).processSync(string);
            return file.value;
        }
    }

    useEffect(() => {

        oS.getCreateInfo().then((data) => {
            let tmp_location = []
            if (data.locations)
                data.locations.map((l) => {
                    tmp_location.push({
                        label: l,
                        value: l
                    })
                })
            setLocations([...tmp_location])
            let tmp = []
            data.types.map((o) => {
                tmp.push({ label: o.name, value: o.id })
            })
            setTypeOptions([...tmp])
            let tmp_visibilities = []
            if (data.visibilities)
                data.visibilities.map((l) => {
                    tmp_visibilities.push({
                        label: l.Name,
                        value: l.ID
                    })
                })
            setVisibilities([...tmp_visibilities])
        })
    }, [])

    useEffect(() => {
        oS.getOpportunityById(props.selectedOpportunity.id).then((data) => {
            setOpportunity(data.info)
            if (data.info.description)
                setDescription(markdownToHtml(data.info.description))
            if (data.info.duration_type)
                setSelectedDurationType({ label: data.info.duration_type, value: data.info.duration_type })
            if (data.info.location)
                setSelectedLocation({ label: data.info.location, value: data.info.location })
            let tmp = []
            if (data.slots && !props.isDuplication) {
                data.slots.map((s) => {
                    tmp.push({
                        id: s.id,
                        title: getTranslationByKey('OPPORTUNITIES.LABLE.MAXPARTICIPANTS') + ': ' + s.max_allowed_participants,
                        allDay: false,
                        start: new Date(s.start_date),
                        end: new Date(s.end_date),
                        max_participants: s.max_allowed_participants
                    })
                })
                setSlotList(tmp)
            }
            if (data.admins && !props.isDuplication) {
                let tmp2 = []
                let o = {}
                data.admins.map((d) => {
                    o = {
                        label: d.full_name + ' (' + d.email + ')',
                        value: d.id
                    }
                    tmp2.push(o)
                })
                setSelectedPM(tmp2)
            }
            if (data.info && data.info.visibility_list) {
                // to edit
                let tmp3 = []
                let o = {}
                data.info.visibility_list.map((v) => {
                    o = {
                        label: v.Name,
                        value: v.ID
                    }
                    tmp3.push(o)
                })
                setSelectedVisibilityList(tmp3)
            }
        })
    }, [props.selectedOpportunity])

    const handleField = (field, e) => {
        let o = opportunity
        if (field === 'start_date' && e) {
            o['start_date'] = new Date(e)
            setOpportunity({ ...o })
        }
        else if (e && e.target && e.target.value) {
            o[field] = e.target.value
            setOpportunity({ ...o })
        }
        else {
            delete o[field]
            setOpportunity({ ...o })
        }
    }

    const htmlToMarkdown = (string) => {
        const file = remark()
            .use(rehypeParse, { emitParseErrors: true, duplicateAttribute: false })
            .use(rehypeRemark)
            .use(remarkStringify)
            .processSync(string);

        return String(file);
    }


    const getData = (date) => {
        let new_date = new Date(date)
        let toStringDate = ""
        toStringDate = new_date.getFullYear().toString() + '-' + ("0" + (new_date.getMonth() + 1)).slice(-2).toString() + '-' + ("0" + new_date.getDate()).slice(-2).toString()
        return toStringDate
    }

    const handleUpdate = (title, value) => {
        let o = opportunity
        if (value && value.target && value.target.value)
            o[title] = value.target.value
        if (opportunity['type']) {

            if (opportunity['type'].id == 2 || opportunity['type_id'] == 2) {
                let tmp = []
                slotList.map((s) => {
                    tmp.push({
                        id: s.id ? s.id : null,
                        start_date: s.start,
                        end_date: s.end,
                        max_participants: s.max_participants
                    })
                })
                o['slots'] = tmp
            }
            o['type_id'] = opportunity['type'].id
            delete o['type']
        }
        if (selectedPM && selectedPM.length > 0) {
            let tmp_pm = []
            selectedPM.map((spm) => {
                tmp_pm.push(spm.value)
            })
            o['admin_ids'] = tmp_pm
        }

        if (description) {
            opportunity['description'] = htmlToMarkdown(description)
        }
        if (selectedVisibilityList && selectedVisibilityList.length > 0) {
            let tmp = []
            selectedVisibilityList.map((v) => {
                tmp.push(v.value)
            })
            o['visibility_ids'] = tmp
            delete o['visibility_list']
        }

        setOpportunity({ ...o })
        let req = { opportunity: o }
        if (selectedType.value == 1 && opportunity['start_date'] && opportunity['start_date'] === '') {
            UseAlertMessage(400, getTranslationByKey('OPPORTUNITIES.LABLE.FILLREQUIREDFIELDS'))
        }
        else if (opportunity['title'] && opportunity['title'] !== ''
            && opportunity['duration'] && opportunity['duration'] !== ''
            && selectedDurationType && selectedType
            && selectedVisibilityList && selectedVisibilityList.length > 0
        ) {
            req = {
                "opportunity": {
                    "id": o.id, // number
                    "title": opportunity['title'], // string
                    "description": opportunity['description'], // string | undefined
                    "department": opportunity['department'], // string | undefined
                    "location": selectedLocation != '' ? selectedLocation.value : '', // string | undefined
                    "job_role": null,
                    "visibility": null,
                    "visibility_ids": o['visibility_ids'], // number[]
                    "start_date": o.start_date, // datetime >= Now
                    "duration_type": o.duration_type, // 'Hours' | 'Days' | 'Weeks' | 'Months'
                    "duration": o.duration, // number
                    "slots": o['slots'],
                    "admin_ids": o['admin_ids'] // number[], creator is added by the BE
                }
            }

            /*
            */
            oS.editOpportunity(opportunity.id, req).then((data) => {
                setOpenRecapDialog(false)
                props.setViewType('fieldView')
                props.setUpdateView(false)
                props.setDuplication(false)
                props.setReloadAll(!props.reloadAll)
            })

        }
        else {
            UseAlertMessage(400, getTranslationByKey('OPPORTUNITIES.LABLE.FILLREQUIREDFIELDS'))
        }
    }


    const handleCreate = (title, value) => {
        if (opportunity['duration'] && opportunity['duration'] !== '' && isNaN(opportunity['duration'])) {
            UseAlertMessage(400, getTranslationByKey('OPPORTUNITIES.LABLE.WARNING.DURATIONISNOTNUMBER'))
        }
        else if (selectedType.value == 1 && opportunity['start_date'] && opportunity['start_date'] === '') {
            UseAlertMessage(400, getTranslationByKey('OPPORTUNITIES.LABLE.FILLREQUIREDFIELDS'))
        }
        else if (opportunity['title'] && opportunity['title'] !== ''
            && opportunity['duration'] && opportunity['duration'] !== ''
            && selectedDurationType && selectedType
        ) {
            let o = opportunity
            if (selectedType && selectedType.value)
                o['type_id'] = selectedType.value
            if (description) {
                o['description'] = htmlToMarkdown(description)
            }
            if (selectedVisibilityList && selectedVisibilityList.length > 0) {
                let tmp = []
                selectedVisibilityList.map((v) => {
                    tmp.push(v.value)
                })
                o['visibility_ids'] = tmp
            }
            if (selectedDurationType && selectedDurationType.label)
                o['duration_type'] = selectedDurationType.label
            if (selectedPM && selectedPM.length > 0) {
                let tmp = []
                selectedPM.map((pm) => {
                    tmp.push(pm.value)
                })
                opportunity['admin_ids'] = tmp
            }
            if (selectedType && selectedType.value == 2) {
                let tmp2 = []
                if (slotList && slotList.length > 0)
                    slotList.map((s) => {
                        tmp2.push({
                            start_date: s.start,
                            end_date: s.end,
                            max_participants: s.max_participants
                        })
                    })
                o['slots'] = tmp2
            }
            let tosend = {
                opportunity: o
            }
            oS.createOpportunity(tosend).then((res) => {

                UseAlertMessage(200, res.message)
                setOpenRecapDialog(false)
                props.setViewType('fieldView')
                props.setUpdateView(false)
                props.setDuplication(false)
                props.setReloadAll(!props.reloadAll)
            })
        } else {
            UseAlertMessage(400, getTranslationByKey('OPPORTUNITIES.LABLE.FILLREQUIREDFIELDS'))
        }
    }

    useEffect(() => {
        setOpportunity(props.selectedOpportunity)
        setSelectedType({
            label: props.selectedOpportunity.type.name,
            value: props.selectedOpportunity.type.id
        })
    }, [props.selectedOpportunity])

    useEffect(() => {
        addListener()

        return () => {
            removeListener()
        }
    }, [selectedPM])

    const handleFieldLocation = (value, field) => {
        if (!value)
            setSelectedLocation(null)
        else if (field === 'location' && value) {
            setSelectedLocation({ label: value, value: value })
        }
    }

    const goToNextPage = () => {
        if (opportunity['duration'] && opportunity['duration'] !== '' && isNaN(opportunity['duration'])) {
            UseAlertMessage(400, getTranslationByKey('OPPORTUNITIES.LABLE.WARNING.DURATIONISNOTNUMBER'))
        }
        else if (selectedType.value == 1 && opportunity['start_date'] && opportunity['start_date'] === '') {
            UseAlertMessage(400, getTranslationByKey('OPPORTUNITIES.LABLE.FILLREQUIREDFIELDS'))
        }
        else if (opportunity['title'] && opportunity['title'] !== ''
            //&& opportunity['department'] && opportunity['department'] !== ''
            && selectedType && selectedVisibilityList && selectedVisibilityList.length > 0
        )
            props.setViewType('chooseSlot')
        else {

            UseAlertMessage(400, getTranslationByKey('OPPORTUNITIES.LABLE.FILLREQUIREDFIELDS'))
        }
    }


    const selectSlot = (slot) => {
        let diff = new Date(slot.end).getTime() - new Date(slot.start).getTime()
        if ((new Date(slot.end).getTime() < new Date().getTime())) {
            UseAlertMessage(404, getTranslationByKey("READYTOGROW.ERROR.PAST"))
        }
        else if (diff > 604800001) {
            UseAlertMessage(404, getTranslationByKey("Non puoi creare uno slot maggiore di 7 giorni"))
        }
        else {
            let start = new Date(slot.start)
            let end = new Date(slot.end)
            setStartSlot(start)
            let st_date = start.toLocaleDateString("en-GB", { year: 'numeric', month: '2-digit', day: '2-digit' }).replaceAll('/', '-');
            let en_date = end.toLocaleDateString("en-GB", { year: 'numeric', month: '2-digit', day: '2-digit' }).replaceAll('/', '-');
            let from_time = start.toLocaleTimeString("en-GB", { hour: '2-digit', minute: '2-digit' })
            let to_time = end.toLocaleTimeString("en-GB", { hour: '2-digit', minute: '2-digit' })
            setEndSlot(new Date(slot.end))
            let options = {
                s_date: st_date,
                e_date: en_date,
                from: from_time,
                to: to_time
            }
            setOptionToAdd(options)
            setOpenCreateDialog(true)

        }
    }

    //dont remove e,view
    const nav = (e, view, type) => {
        if (type === 'DATE')
            setView('week')
    }

    const addListener = () => {
        window.addEventListener("keyup", addManual, false);
        window.addEventListener("keydown", clear, false);
    }

    const removeListener = () => {
        window.removeEventListener("keyup", addManual, false);
        window.removeEventListener("keydown", clear, false);
    }
    const addManual = () => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(doneTyping, doneTypingInterval);
    }

    const clear = () => {
        clearTimeout(typingTimer);
    }

    //user is "finished typing," do something
    const doneTyping = () => {
        let element = document.querySelector(' .multiselect_admin > div > div.dropdown-content > div > div > div > input[type=text]')
        if (element && element.value) {
            oS.getUsersList(element.value).then((data) => {
                let tmp = []
                let o = {}
                data.users.map((d) => {
                    o = {
                        label: d.full_name + ' (' + d.email + ')',
                        value: d.id
                    }
                    tmp.push(o)
                })
                setUsersList([...tmp])
            })
        }
    }

    const createSlot = (e) => {
        if (maxParticipants !== '') {
            let slots = slotList
            let st_date = new Date((optionsToAdd.s_date.split('-')[2]) + '-' + (optionsToAdd.s_date.split('-')[1]) + '-' + optionsToAdd.s_date.split('-')[0] + 'T' + optionsToAdd.from.split(':')[0] + ':' + optionsToAdd.from.split(':')[1])
            let en_date = new Date((optionsToAdd.e_date.split('-')[2]) + '-' + (optionsToAdd.e_date.split('-')[1]) + '-' + optionsToAdd.e_date.split('-')[0] + 'T' + optionsToAdd.to.split(':')[0] + ':' + optionsToAdd.to.split(':')[1])

            let s = {
                id: null,
                title: getTranslationByKey('OPPORTUNITIES.LABLE.MAXPARTICIPANTS') + ': ' + maxParticipants,
                allDay: false,
                start: st_date,
                end: en_date,
                max_participants: maxParticipants
            }
            slots.push(s)
            setSlotList([...slots])
            setMaxParticipants('')
            setOpenCreateDialog(false)
        }
    }

    const openDetailPopup = (e) => {
        setStartSlot(e.start)
        setEndSlot(e.end)
        setSlotID(e.id)
        setOpenDetailDialog(true)
    }

    const removeSlot = (e) => {
        slotList.map((o, i) => {
            if (o.id == slotID)
                slotList.splice(i, 1)
        })
        setSlotList([...slotList])
        setOpenDetailDialog(false)
    }

    let slots = ''
    if (slotList && slotList.length > 0)
        /*    slots = <ScrollContainer className="flex">
                {slotList.map((d) => <div className="recap_slot_card">
                    {new Date(d.start).toLocaleDateString() === new Date(d.end).toLocaleDateString() ?
                        <div className="flex"><div className="bold"> Date: &nbsp; </div> {new Date(d.start).toLocaleDateString().replaceAll('/', '-')}</div>
                        :
                        <>
                            <div className="flex"><div className="bold">Start Date: &nbsp; </div> {new Date(d.start).toLocaleDateString().replaceAll('/', '-')}</div>
                            <div className="flex"><div className="bold">End Date: &nbsp; </div> {new Date(d.end).toLocaleDateString().replaceAll('/', '-')}</div>
                        </>
                    }
                    <div className="flex"><div className="bold"> Max Participants: &nbsp; </div> {d.max_participants}</div>
                </div>)}

            </ScrollContainer>*/
        slots = <ScrollContainer className="flex">
            {slotList.map((d) => <DateSlotCard date={d} />)}
        </ScrollContainer>

    let pms = ''
    if (selectedPM && selectedPM.length > 0)
        /*  pms = selectedPM.map((pm) => <div className="flex"><div><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
              <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
          </svg></div><div>{pm.label.split('(')[0]}</div></div>)
  */
        pms = selectedPM.map((pm, i) => ((i != 0 ? ', ' : '') + pm.label.split(' (')[0]))

    let vis = ''
    if (selectedVisibilityList && selectedVisibilityList.length > 0)
        vis = selectedVisibilityList.map((pm, i) => ((i != 0 ? ', ' : '') + pm.label.split(' (')[0]))

    const Tooltip = <Popover id="popover-basic" Style={'box-shadow: "-1px 2px 5px rgba(0,0,0,0.1)"'}>
        <div Style={'padding:10px'}>
            The "visibility" field will be necessary for admins to set the correct visibility. Please enter the details of who should have visibility on this opportunity (e.g. GucciTech Italy)
        </div>
    </Popover >



    return (
        <>
            {opportunity && props.viewType === 'fieldView' ?
                <div>
                    <div className="bold">{props.isDuplication ? getTranslationByKey('OPPORTUNITIES.BUTTON.CREATEOPPORTUNITY') : getTranslationByKey('OPPORTUNITIES.BUTTON.UPDATEOPPORTUNITY')}</div>
                    <hr Style="width: 50%;" />
                    <div>
                        <div className="flex">
                            <div Style="width: 50%; padding-right: 20px; text-align: -webkit-left;">
                                <div className="bold" Style="text-align: -webkit-left;">{getTranslationByKey('OPPORTUNITIES.LABLE.TITLE')} {opportunity['title'] && opportunity['title'] !== '' ? '' : '*'}</div>
                                <input type="text" className="opportunities_input" placeholder="Enter title of the opportunity" value={opportunity && opportunity.title ? opportunity.title : ''} onChange={(e) => handleField('title', e)}></input>
                            </div>
                            <div Style="width: 50%; text-align: -webkit-left">
                                <div className="bold" Style="text-align: -webkit-left;">{getTranslationByKey('OPPORTUNITIES.LABLE.TYPE')} {selectedType ? '' : '*'}</div>

                                <Select
                                    isDisabled={true}
                                    value={selectedType}
                                    onChange={(e) => setSelectedType(e)}
                                    options={typeOptions}
                                    className="opportunities_select opportunities_select_disabled"
                                    placeholder={"Project"}
                                    styles={customStylesDisabled}
                                />
                            </div>
                        </div>
                        <div className="flex" Style="margin-top: 7px;">
                            <div Style="width: 50%; padding-right: 20px; text-align: -webkit-left;">
                                <div className="bold" Style="text-align: -webkit-left;">{getTranslationByKey('OPPORTUNITIES.LABLE.PM')} {opportunity['created_by'] && opportunity['created_by'] !== '' ? '' : '*'}</div>
                                <input disabled className="opportunities_input" value={props.isDuplication ? props.user.firstname + ' ' + props.user.lastname : opportunity['created_by']} ></input>
                            </div>
                            <div Style="width: 50%; text-align: -webkit-left">
                                <div className="bold" Style="text-align: -webkit-left;">{getTranslationByKey('OPPORTUNITIES.LABLE.DEPARTMENT')} {/*opportunity['department'] && opportunity['department'] !== '' ? '' : '*'*/}</div>
                                <input className="opportunities_input" placeholder="Enter department related to opportunity" value={opportunity && opportunity.department ? opportunity.department : ''} onChange={(e) => handleField('department', e)}></input>
                            </div>
                        </div>
                        <div className="flex" Style="margin-top: 7px;">
                            {opportunity && opportunity.type && opportunity.type.id == 1 ?
                                <div className="opportunities_datepicker_container" Style="width: 50%; text-align: -webkit-left;">
                                    <div className="bold " Style="text-align: -webkit-left;">{getTranslationByKey('OPPORTUNITIES.LABLE.STARTDATE')} {opportunity['start_date'] && opportunity['start_date'] !== '' ? '' : '*'}</div>
                                    <DatePicker
                                        showIcon
                                        className="meeting_rooms_datepicker opportunities_datepicker"
                                        selected={opportunity.start_date ? new Date(opportunity.start_date) : ''}
                                        minDate={new Date()}
                                        onChange={(date) => { handleField('start_date', date) }}
                                        placeholderText="gg/mm/yyyy"
                                        popperPlacement="bottom-center"
                                        dateFormat={"dd/MM/yyyy"}
                                    />
                                </div> : ''}
                            {opportunity && opportunity.type && opportunity.type.id == 2 ?
                                <div Style="width: 50%; text-align: -webkit-left;padding-right: 20px;">
                                    <div className="bold" Style="text-align: -webkit-left;">{getTranslationByKey('OPPORTUNITIES.LABLE.LOCATION')}</div>
                                    {/* <input className="opportunities_input" placeholder="Enter location related to opportunity" value={opportunity['location']} onChange={(e) => handleField('location', e)}></input>
                          */}
                                    <SelectComponent selectedOption={selectedLocation} options={locations} setSelectedOption={handleFieldLocation} field={'location'} isClearable={true} />
                                </div> : ''}
                            <div Style="width: 25%; padding-right: 20px; text-align: -webkit-left">
                                <div className="bold" Style="text-align: -webkit-left;">{getTranslationByKey('OPPORTUNITIES.LABLE.DURATION')}</div>
                                <div className="opportunities_datepicker_container">
                                    <Select
                                        value={selectedDurationType}
                                        onChange={(e) => setSelectedDurationType(e)}
                                        options={[{ label: 'Days', value: 'Days' }, { label: 'Weeks', value: 'Weeks' }, { label: 'Months', value: 'Months' }]}
                                        className="opportunities_select"
                                        placeholder={"Months"}
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                            <div Style="width: 25%; text-align: -webkit-left">
                                <div className="bold" Style="text-align: -webkit-left;">{getTranslationByKey('OPPORTUNITIES.LABLE.NUMBEROF')} {selectedDurationType && selectedDurationType.label ? selectedDurationType.label : ''}</div>
                                <input className="opportunities_input" value={opportunity && opportunity.duration ? opportunity.duration : ''} onChange={(e) => handleField('duration', e)}></input>
                            </div>
                        </div>
                        <div Style="margin-top: 7px;">
                            {opportunity && opportunity.type && opportunity.type.id == 1 ?
                                <div Style="width: 100%; text-align: -webkit-left;">
                                    <div className="bold" Style="text-align: -webkit-left;">{getTranslationByKey('OPPORTUNITIES.LABLE.LOCATION')}</div>
                                    <SelectComponent selectedOption={selectedLocation} options={locations} setSelectedOption={handleFieldLocation} field={'location'} isClearable={true} />
                                </div> : ''
                            }
                        </div>
                        <div Style="margin-top: 7px;">
                            <div Style="width: 100%; text-align: -webkit-left; flex-wrap: wrap;  display: flex;">
                                <div className="bold" Style="text-align: -webkit-left; margin-right:5px">{getTranslationByKey('OPPORTUNITIES.LABLE.VISIBILITY')} {opportunity['visibility'] && opportunity['visibility'] !== '' ? '' : '*'}</div>
                                {/*  <OverlayTrigger
                                    placement="top"
                                    trigger="click"
                                    rootClose
                                    overlay={Tooltip}
                                >
                                    <Form.Text className="show_more">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" fill="currentColor" class="bi bi-info-circle pointer mt--3" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                                        </svg>
                                    </Form.Text>
                                </OverlayTrigger>*/}
                            </div>
                            <MultiSelectComponent id={opportunity.is_draft ? 'multiselect_admin' : (props.isDuplication ? 'multiselect_admin' : 'multiselect_admin multiselect_admin_disabled')} label={"Select..."} selectedGuest={selectedVisibilityList} setSelectedGuest={setSelectedVisibilityList} options={visibilities} setOptions={selectedVisibilityList} setModalLoading={null} is_draft={opportunity.is_draft} isDuplication={props.isDuplication} />

                        </div>
                        <div Style="margin-top: 7px;">
                            <div Style="width: 100%; text-align: -webkit-left;">
                                <div className="bold" Style="text-align: -webkit-left;" >{getTranslationByKey('OPPORTUNITIES.LABLE.ADDPM')}</div>
                                <MultiSelectComponent id={'multiselect_admin'} label={"Select a guest"} selectedGuest={selectedPM} setSelectedGuest={setSelectedPM} options={usersList} setOptions={setUsersList} setModalLoading={() => console.log('')} />
                            </div>
                        </div>
                        <div Style="margin-top: 7px;">
                            <div Style="width: 100%; text-align: -webkit-left;">
                                <div className="bold" Style="text-align: -webkit-left;">{getTranslationByKey('OPPORTUNITIES.LABLE.DESCRIPTION')}</div>
                                <ReactQuill
                                    onChange={(val) => setDescription(val)}
                                    theme='snow'
                                    className='opportunities_description'
                                    style={{ height: '200px' }}
                                    modules={modules}
                                    value={description}
                                />
                            </div>
                        </div>
                        <div Style="margin-top: 60px;">
                            <div className="opportunities_save_button" onClick={selectedType && selectedType.label === 'Experience' ? goToNextPage : (props.isDuplication ? handleCreate : handleUpdate)}>
                                {selectedType && selectedType.label === 'Experience' ? getTranslationByKey('BUTTON.TITLE.NEXT') : (props.isDuplication ? getTranslationByKey('OPPORTUNITIES.BUTTON.CREATEOPPORTUNITY') : getTranslationByKey('OPPORTUNITIES.BUTTON.UPDATE'))}
                            </div>
                        </div>
                    </div>

                </div >
                : (opportunity && props.viewType !== 'fieldView' ? <div>
                    <div className="calendar_card opportunities_calendar_card" Style={'margin: -20px 20px 0px 20px; '}>
                        <Calendar
                            localizer={localizer}
                            eventPropGetter={event => ({
                                style: {
                                    background: event.is_booked ? "linear-gradient(180deg, #f0ba49 70%, rgb(212 212 212 / 41%) 100%)" : "linear-gradient(180deg, #000000 70%, #8c8c8c 100%)", borderColor: '#000000', whiteSpace: 'break-spaces'
                                }
                            })}
                            events={slotList}
                            startAccessor="start"
                            endAccessor="end"
                            onSelectEvent={(e) => openDetailPopup(e)}
                            onShowMore={() => setView('week')}
                            allDayAccessor={true}
                            view={view}
                            onNavigate={(e, t, v) => nav(e, t, v)}
                            onView={(v) => setView(v)}
                            selectable={true}
                            onSelectSlot={(e) => selectSlot(e)}
                            views={['month', 'week']}
                            min={new Date(2024, 10, 0, 8, 0, 0)}
                            max={new Date(2024, 10, 0, 20, 0, 0)}
                            style={{ height: "85vh" }}
                            step={30}

                        />
                        <div Style="margin-top: 70px;">
                            <div className="opportunities_save_button" onClick={selectedType && selectedType.value == 2 ? () => setOpenRecapDialog(true) : (props.isDuplication ? handleCreate : handleUpdate)}>
                                {props.isDuplication ? getTranslationByKey('OPPORTUNITIES.BUTTON.CREATEOPPORTUNITY') : getTranslationByKey('OPPORTUNITIES.BUTTON.UPDATE')}
                            </div>
                        </div>
                    </div></div> : '')
            }
            <Dialog
                open={openCreateDialog}
                onClose={() => setOpenCreateDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="alert-dialog-title bold" className='bold' >
                    {getTranslationByKey('OPPORTUNITIES.LABLE.SLOTCREATING')}
                </DialogTitle>
                <DialogContent>
                    <div>
                        <br />
                        <div className="flex">
                            <div className="bold">
                                {new Date(startSlot).toLocaleDateString("en-GB", { day: '2-digit', month: '2-digit', year: 'numeric' }).replaceAll(',', '-').toString() !== new Date(endSlot).toLocaleDateString("en-GB", { day: '2-digit', month: '2-digit', year: 'numeric' }).replaceAll(',', '-').toString() ? 'Start' : ''} Date: &nbsp; </div>  {startSlot ?
                                    new Date(startSlot).toLocaleDateString("en-GB", { day: '2-digit', month: '2-digit', year: 'numeric' }).replaceAll(',', '-')
                                    : ''}</div>
                        {new Date(startSlot).toLocaleDateString("en-GB", { day: '2-digit', month: '2-digit', year: 'numeric' }).replaceAll(',', '-').toString() !== new Date(endSlot).toLocaleDateString("en-GB", { day: '2-digit', month: '2-digit', year: 'numeric' }).replaceAll(',', '-').toString() ?
                            <div className="flex"><div className="bold"> {getTranslationByKey('OPPORTUNITIES.LABLE.ENDDATE')}: &nbsp; </div>  {endSlot ?
                                new Date(endSlot).toLocaleDateString("en-GB", { day: '2-digit', month: '2-digit', year: 'numeric' }).replaceAll(',', '-')
                                : ''}
                            </div> : ''}
                        <div className="flex"><div className="bold"> {getTranslationByKey('OPPORTUNITIES.LABLE.TIME')}: &nbsp; </div>  {startSlot ? (startSlot.getHours() < 10 ? '0' + startSlot.getHours() : startSlot.getHours()) + ':' + (startSlot.getMinutes() == 0 ? '00' : startSlot.getMinutes()) : ''}
                            -
                            {endSlot ? (endSlot.getHours() < 10 ? '0' + endSlot.getHours() : endSlot.getHours()) + ':' + (endSlot.getMinutes() == 0 ? '00' : endSlot.getMinutes()) : ''}
                        </div>
                        <br />
                        <div Style="width: 40%; text-align: -webkit-left">
                            <div className="bold" Style="text-align: -webkit-left;">{getTranslationByKey('OPPORTUNITIES.LABLE.MAXPARTICIPANTS')} {document.getElementsByClassName('opportunities_input') && document.getElementsByClassName('opportunities_input')[0]
                                && document.getElementsByClassName('opportunities_input')[0].checkValidity() ? '' : '*'}</div>
                            <input className="opportunities_input" type="text" pattern="[0-9]{1,9}" required title={getTranslationByKey('OPPORTUNITIES.LABLE.WARNING.INSERTNUMBER')} value={maxParticipants} onChange={(e) => setMaxParticipants(e.target.value)} ></input>
                            {document.getElementsByClassName('opportunities_input') && document.getElementsByClassName('opportunities_input')[0]
                                && document.getElementsByClassName('opportunities_input')[0].checkValidity() ? '' :
                                (document.getElementsByClassName('opportunities_input') && document.getElementsByClassName('opportunities_input')[0]
                                    && !document.getElementsByClassName('opportunities_input')[0].checkValidity() ? <span className="error_span">{getTranslationByKey('OPPORTUNITIES.LABLE.WARNING.INSERTNUMBER')}</span> : '')}
                        </div>
                        <br />

                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenCreateDialog(false)} className="modal_button">
                        {getTranslationByKey('OPPORTUNITIES.BUTTON.CANCEL')}
                    </Button>
                    <Button className="red dialog_close_button" autoFocus onClick={(e) => createSlot(e)} disabled={!maxParticipants || maxParticipants < 0 || (document.getElementsByClassName('opportunities_input') && document.getElementsByClassName('opportunities_input')[0]
                        && !document.getElementsByClassName('opportunities_input')[0].checkValidity())}>
                        {getTranslationByKey('OPPORTUNITIES.BUTTON.CREATEOPPORTUNITY')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openRecapDialog}
                onClose={() => setOpenRecapDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="alert-dialog-title bold" className='bold' >
                    {getTranslationByKey('OPPORTUNITIES.BUTTON.UPDATEOPPORTUNITY')}
                </DialogTitle>
                <DialogContent>
                    <div>
                        <div className="wrap">
                            {opportunity && opportunity['title'] ? <DisabledTextBox label={'Title'} value={opportunity['title']} /> : ''}
                            {selectedType ? <DisabledTextBox label={'Type'} value={selectedType.label} /> : ''}
                            <DisabledTextBox label={'Project Manager'} value={props.user.firstname + ' ' + props.user.lastname} />
                            {opportunity && opportunity['department'] ? <DisabledTextBox label={'Department'} value={opportunity['department']} /> : ''}
                            {opportunity && opportunity['start_date'] ? <DisabledTextBox label={'Start Date'} value={new Date(opportunity['start_date']).getDate() + '/' + parseInt(new Date(opportunity['start_date']).getMonth() + 1) + '/' + new Date(opportunity['start_date']).getFullYear()} /> : ''}
                            {opportunity && opportunity['location'] ? <DisabledTextBox label={'Location'} value={opportunity['location']} /> : ''}
                        </div>
                        {/*description ? <DisabledTextBox label={'Description'} value={description} /> : ''*/}
                        <div Style={' margin: 5px 2%'}>
                            {selectedVisibilityList && selectedVisibilityList.length > 0 ? <div className="flex mt-3"><div className="bold"> {getTranslationByKey('OPPORTUNITIES.LABLE.VISIBILITIES')} </div></div> : ''}
                            {selectedVisibilityList && selectedVisibilityList.length > 0 ? vis : ''}
                            <br />
                            {slotList && slotList.length > 0 ? <div className="flex mt-3"><div className="bold"> {getTranslationByKey('OPPORTUNITIES.LABLE.SLOTS')}</div></div> : ''}
                            <div className="flex">
                                {slots}
                            </div>
                            {selectedPM && selectedPM.length > 0 ? <div className="flex mt-3"><div className="bold"> {getTranslationByKey('OPPORTUNITIES.LABLE.OTHERPM')}: </div></div> : ''}
                            <div className="flex">
                                {pms}
                            </div>

                        </div>

                        <br />

                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenRecapDialog(false)} className="modal_button">
                        {getTranslationByKey('OPPORTUNITIES.BUTTON.CANCEL')}
                    </Button>
                    <Button className="red dialog_close_button" autoFocus onClick={props.isDuplication ? handleCreate : handleUpdate}>
                        {props.isDuplication ? getTranslationByKey('OPPORTUNITIES.BUTTON.CREATEOPPORTUNITY') : getTranslationByKey('OPPORTUNITIES.BUTTON.UPDATE')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDetailDialog}
                onClose={() => setOpenDetailDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="sm"

            >
                <DialogTitle id="alert-dialog-title " className='bold' >
                    {getTranslationByKey('MENU.READYTOGROW.TITLE')}
                </DialogTitle>

                <DialogContent>
                    <div>


                        {new Date(startSlot).toLocaleDateString("en-GB", { day: '2-digit', month: '2-digit', year: 'numeric' }).replaceAll(',', '-').toString() !== new Date(endSlot).toLocaleDateString("en-GB", { day: '2-digit', month: '2-digit', year: 'numeric' }).replaceAll(',', '-').toString() ?
                            <>
                                {getTranslationByKey('OPPORTUNITIES.LABLE.START')} {getTranslationByKey("READYTOGROW.CREATE.DATE")}
                                <br />
                                <div className='bold gucci-text-bold-m'>    {new Date(startSlot).toLocaleDateString("en-GB", { day: '2-digit', month: '2-digit', year: 'numeric' }).replaceAll(',', '-').toString()}
                                </div>
                                {getTranslationByKey('OPPORTUNITIES.LABLE.END')} {getTranslationByKey("READYTOGROW.CREATE.DATE")}
                                <br />
                                <div className='bold gucci-text-bold-m'>   {new Date(endSlot).toLocaleDateString("en-GB", { day: '2-digit', month: '2-digit', year: 'numeric' }).replaceAll(',', '-').toString()}
                                </div>  </>
                            :
                            <>
                                {getTranslationByKey("READYTOGROW.CREATE.DATE")}
                                <br />
                                <div className='bold gucci-text-bold-m'>   {new Date(startSlot).toLocaleDateString("en-GB", { day: '2-digit', month: '2-digit', year: 'numeric' }).replaceAll(',', '-').toString()}
                                </div> </>}

                        {new Date(startSlot).toLocaleDateString("en-GB", { day: '2-digit', month: '2-digit', year: 'numeric' }).replaceAll(',', '-').toString() !== new Date(endSlot).toLocaleDateString("en-GB", { day: '2-digit', month: '2-digit', year: 'numeric' }).replaceAll(',', '-').toString()
                            ? '' : <>
                                {getTranslationByKey("READYTOGROW.CREATE.TIME")}

                                <br />
                                <div className='gucci-text-bold-m'>
                                    {startSlot ? (startSlot.getHours() < 10 ? '0' + startSlot.getHours() : startSlot.getHours()) + ':' + (startSlot.getMinutes() == 0 ? '00' : startSlot.getMinutes()) : ''}
                                    -
                                    {endSlot ? (endSlot.getHours() < 10 ? '0' + endSlot.getHours() : endSlot.getHours()) + ':' + (endSlot.getMinutes() == 0 ? '00' : endSlot.getMinutes()) : ''}
                                </div>
                            </>}
                        <br />
                        {startSlot && new Date().getTime() > startSlot.getTime() ? '' : <Button onClick={() => removeSlot()} className="remove_modal_button">
                            {getTranslationByKey("READYTOGROW.REMOVE.SLOT")}


                        </Button>}
                        <div className="bold"><i>{getTranslationByKey('OPPORTUNITIES.LABLE.IFREMOVESLOT')}</i></div>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDetailDialog(false)} className="modal_button">
                        {getTranslationByKey('OPPORTUNITIES.BUTTON.CANCEL')}
                    </Button>
                </DialogActions>
            </Dialog></>
    )
}