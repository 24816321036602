import React, { useEffect, useState } from "react"
import { OpportunitiesDetailHeader } from "./OpportunitiesDetailHeader"
import Markdown from 'markdown-to-jsx'

export function OppotunitiesOverview(props) {

    return (
        <div>
            <div className="flex">
                <div className="opportunities_detail_cont">
                    <div className="opportunities_detail_cont_mobile">
                        <OpportunitiesDetailHeader handleDuplicateView={props.handleDuplicateView} userView={props.userView} handleRemoveOpportunity={props.handleRemoveOpportunity} detail={props.detail} getData={props.getData} setUpdateView={props.setUpdateView} reload={props.reload} />
                        <div className="opportunities_info_cont" Style="padding: 20px;">
                            <div className="opportunities_overview_description">

                                <div className="opportunities_info_text_mobile" Style="font-size: 14px;"><Markdown>{props.detail.info.description}</Markdown></div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}
