function getTraslationByKey(key) {
    //let translation = ''
    //
    //if (sessionStorage.getItem('translation')) {
    //    translation = JSON.parse(sessionStorage.getItem('translation'))
    //    return translation[key].v
    //} else {
    return ''
    //}
}
export function getTranslationByKey(key) {
    let translation = ''
    let translation_en = ''
    if (sessionStorage.getItem('translation')) {
        translation = JSON.parse(sessionStorage.getItem('translation'))
        translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
        return translation[key] && translation[key].v ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
    } else {
        return key
    }
}

export const home_title = "Wellness Lab";
export const my_bookings = getTraslationByKey('my_bookings');
export const add_button = getTraslationByKey('add');
export const customStyles = {
    control: (base, { isFocused, isSelected }) => ({
        ...base,
        background: window.innerWidth > 1000 ? "#fff" : "#f0efef",
        minHeight: '30px',
        height: '30px',
        border: 0,
        // Overwrittes the different states of border
        borderColor: isSelected ? null : null,
        // Removes weird border around container
        boxShadow: isFocused ? null : null,
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        padding: '0 6px'
    }),
    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
    }),
    option: (styles, { isFocused, isHovered }) => ({
        ...styles,
        backgroundColor: isFocused || isHovered ? "#c5c5c7" : "transparent",
        color: "black",
        "&:hover": {
            backgroundColor: "#c5c5c7"
        },
        color: '#000',
        zIndex: 1
    }),
    menuList: (base, { isFocused, isSelected }) => ({
        ...base,
        background: window.innerWidth > 1000 ? "#fff" : "#f0efef",
        // kill the white space on first and last option
        padding: 0,
        "max-height": "220px",
        "::-webkit-scrollbar-thumb": {
            background: "#000 !important"
        },
    })
};

export const customStylesMobileFeedbackForm = {
    control: (base, { isFocused, isSelected }) => ({
        ...base,
        background: "#fff",
        minHeight: '30px',
        height: '30px',
        border: 0,
        // Overwrittes the different states of border
        borderColor: isSelected ? null : null,
        // Removes weird border around container
        boxShadow: isFocused ? null : null,
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        padding: '0 6px'
    }),
    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
    }),
    option: (styles, { isFocused, isHovered }) => ({
        ...styles,
        backgroundColor: isFocused || isHovered ? "#c5c5c7" : "transparent",
        color: "black",
        "&:hover": {
            backgroundColor: "#c5c5c7"
        },
        color: '#000',
        zIndex: 1
    }),
    menuList: (base, { isFocused, isSelected }) => ({
        ...base,
        background: "#fff",
        // kill the white space on first and last option
        padding: 0,
        "max-height": "220px",
        "::-webkit-scrollbar-thumb": {
            background: "#000 !important"
        },
    })
};

export const customStylesDisabled = {
    control: (base, { isFocused, isSelected }) => ({
        ...base,
        background: "#f5f5f5",
        minHeight: '30px',
        height: '30px',
        border: 0,
        // Overwrittes the different states of border
        borderColor: isSelected ? null : null,
        // Removes weird border around container
        boxShadow: isFocused ? null : null,
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        padding: '0 6px'
    }),
    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
    }),
    option: (styles, { isFocused, isHovered }) => ({
        ...styles,
        backgroundColor: isFocused || isHovered ? "#c5c5c7" : "transparent",
        color: "black",
        "&:hover": {
            backgroundColor: "#c5c5c7"
        },
        color: '#000',
        zIndex: 1
    }),
    menuList: (base, { isFocused, isSelected }) => ({
        ...base,
        background: window.innerWidth > 1000 ? "#fff" : "#f0efef",
        // kill the white space on first and last option
        padding: 0,
        "max-height": "220px",
        "::-webkit-scrollbar-thumb": {
            background: "#000 !important"
        },
    })
};

