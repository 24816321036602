import React, { Component } from "react";
import { DeskService } from "../Services/Services";
import Markdown from 'markdown-to-jsx'
import { MapGoogle } from "./MapGoogle";
import { Carousel } from 'react-responsive-carousel';
import { LoadingComponentWeb } from "./LoadingComponent";

class InfoUtili extends Component {

    constructor(props) {

        super(props)
        this.state = {
            isLoading: true,
            id: props.id,
            tutorialPopup: false,
            selectedTutorialPage: 0,
            tutorial: null,
            ds: new DeskService(),
            newInfo: [],
            info: [],
            street_address: '',
            currentRidesView: 0
        }

        this.update = this.update.bind(this)
        this.setLoading = this.setLoading.bind(this)
        this.setInfo = this.setInfo.bind(this)
        this.goBack = this.goBack.bind(this)
        this.openTutorialPopup = this.openTutorialPopup.bind(this)
        this.changeTutorialPage = this.changeTutorialPage.bind(this)
        this.handleDirection = this.handleDirection.bind(this)
    }

    componentDidMount() {
        if (this.props.id) {
            //this.state.ds.getInfoLocationv2(this.props.id).then((data) => {
            //    this.setInfo(data)
            //})
            this.state.ds.getInfoLocationv4(this.props.id).then((data) => {
                this.setState({
                    newInfo: data.additional_menu[0],
                    street_address: data.street_address,
                    isLoading: false
                })
            })
        }
        else {
            this.setSubmenuInfo(this.props.office)
        }

        window.history.pushState(null, null, window.location.pathname)
        window.addEventListener('popstate', this.onBackButtonEvent)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.office.id != this.props.office.id) {
            this.update()
        }
        return prevProps.office.id != this.props.office.id
    }

    getTranslationByKey(key) {
        let translation = ''
        let translation_en = ''
        if (sessionStorage.getItem('translation')) {
            translation = JSON.parse(sessionStorage.getItem('translation'))
            translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
            return translation[key] && translation[key].v ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
        } else {
            return key
        }
    }

    update() {
        this.setLoading().then(
            this.state.ds.getInfoLocationv2(this.props.office.id).then((data) => {
                this.setInfo(data)
            })
        )
    }

    async setLoading() {
        this.setState({
            isLoading: true
        })
    }

    onBackButtonEvent = (e) => {
        e.preventDefault();
        window.location.reload()
    }

    setInfo(info) {
        this.setState({
            info: info,
            wellnessLab: info.modules && info.modules.useful_info && info.modules.useful_info.location_hours && info.modules.useful_info.location_hours.wellness_lab ? info.modules.useful_info.location_hours.wellness_lab : null,
            butlerService: info.modules && info.modules.useful_info && info.modules.useful_info.location_hours && info.modules.useful_info.location_hours.butler_service ? info.modules.useful_info.location_hours.butler_service : null,
            location: info.modules && info.modules.useful_info && info.modules.useful_info.location_hours && info.modules.useful_info.location_hours.location ? info.modules.useful_info.location_hours.location : null,
            bar: info.modules && info.modules.useful_info && info.modules.useful_info.location_hours && info.modules.useful_info.location_hours.bar ? info.modules.useful_info.location_hours.bar : null,
            canteen: info.modules && info.modules.useful_info && info.modules.useful_info.location_hours && info.modules.useful_info.location_hours.canteen ? info.modules.useful_info.location_hours.canteen : null,
            busRides: info.modules && info.modules.useful_info && info.modules.useful_info && info.modules.useful_info.bus_rides ? info.modules.useful_info.bus_rides : null,
            street_address: info.street_address,
            canteen_menu: info.modules && info.modules.useful_info && info.modules.useful_info && info.modules.useful_info.canteen_menu_link ? info.modules.useful_info.canteen_menu_link : '',
            isLoading: false
        })
    }
    setSubmenuInfo(info) {
        this.setState({
            newInfo: info,
            isLoading: false
        })
    }

    goBack() {
        this.props.select('offices', this.props.selected_office)
        this.props.setHidden(false)
    }

    openTutorialPopup() {
        this.setState({
            tutorialPopup: !this.state.tutorialPopup,
            selectedTutorialPage: 0
        })
    }

    changeTutorialPage(type) {
        if (type === 'prev')
            this.setState({
                selectedTutorialPage: this.state.selectedTutorialPage - 1
            })
        else {
            this.setState({
                selectedTutorialPage: this.state.selectedTutorialPage + 1
            })
        }
    }

    getInfoBox(list) {
        let ret = ''
        /**/
        let selectedToday = 'background-color: #000000; color: #ffffff; border-radius: 5px;' + 'padding: 2px 3px;' + 'width: 100%;    display: -webkit-inline-box;';
        let infoLabel = '  font-family: "GucciSans-Bold";        float: left;        width: 50%; text-align: -webkit-left;        padding-left: 7%;';
        let infoLabelSelected = '  font-family: "GucciSans-Bold";   text-align: -webkit-left;        padding-left: 7%;     float: left;   position:relative;     width: 50%;  -webkit-text-fill-color: rgba(255, 255, 255, 1);-webkit-opacity: 1;';

        ret = <div className="info_row empty_row  " >
            <div className="info_utili_title_cont">
                <div className="info_utili_title">{this.getTranslationByKey(list.name)} {list.name === "WS.LOCATION.INFO.CANTEEN" ? <span className="extra_info_button book pointer" onClick={() => window.open(this.state.newInfo.canteen_menu_link, '_blank')}>(Menu)</span> : ''}</div>
            </div>
            <div className="pm_info_row">
                <div className="timetable">
                    <div className={new Date().getDay() == 1 ? "pm_info_row_timetable selected_today" : "pm_info_row_timetable"} Style={new Date().getDay() == 1 ? selectedToday : ''}>
                        <div Style={new Date().getDay() == 1 ? infoLabelSelected : infoLabel}>{this.getTranslationByKey('DAY.MONDAY')}</div> <div className="pm_info_value">{this.getTranslationByKey(list.monday)}</div>
                    </div>
                    <div className={new Date().getDay() == 2 ? "pm_info_row_timetable selected_today" : "pm_info_row_timetable"} Style={new Date().getDay() == 2 ? selectedToday : ''}>
                        <div Style={new Date().getDay() == 2 ? infoLabelSelected : infoLabel}>{this.getTranslationByKey('DAY.TUESDAY')}</div> <div className="pm_info_value">{this.getTranslationByKey(list.tuesday)}</div>
                    </div>
                    <div className={new Date().getDay() == 3 ? "pm_info_row_timetable selected_today" : "pm_info_row_timetable"} Style={new Date().getDay() == 3 ? selectedToday : ''}>
                        <div Style={new Date().getDay() == 3 ? infoLabelSelected : infoLabel}>{this.getTranslationByKey('DAY.WEDNESDAY')}</div> <div className="pm_info_value">{this.getTranslationByKey(list.wednesday)}</div>
                    </div>
                    <div className={new Date().getDay() == 4 ? "pm_info_row_timetable selected_today" : "pm_info_row_timetable"} Style={new Date().getDay() == 4 ? selectedToday : ''}>
                        <div Style={new Date().getDay() == 4 ? infoLabelSelected : infoLabel}>{this.getTranslationByKey('DAY.THURSDAY')}</div> <div className="pm_info_value">{this.getTranslationByKey(list.thursday)}</div>
                    </div>
                    <div className={new Date().getDay() == 5 ? "pm_info_row_timetable selected_today" : "pm_info_row_timetable"} Style={new Date().getDay() == 5 ? selectedToday : ''}>
                        <div Style={new Date().getDay() == 5 ? infoLabelSelected : infoLabel}>{this.getTranslationByKey('DAY.FRIDAY')}</div> <div className="pm_info_value">{this.getTranslationByKey(list.friday)}</div>
                    </div>
                    <div className={new Date().getDay() == 6 ? "pm_info_row_timetable selected_today" : "pm_info_row_timetable"} Style={new Date().getDay() == 6 ? selectedToday : ''}>
                        <div Style={new Date().getDay() == 6 ? infoLabelSelected : infoLabel}>{this.getTranslationByKey('DAY.SATURDAY')}</div> <div className="pm_info_value">{this.getTranslationByKey(list.saturday)}</div>
                    </div>
                    <div className={new Date().getDay() == 7 ? "pm_info_row_timetable selected_today" : "pm_info_row_timetable"} Style={new Date().getDay() == 7 ? selectedToday : ''}>
                        <div Style={new Date().getDay() == 7 ? infoLabelSelected : infoLabel}>{this.getTranslationByKey('DAY.SUNDAY')}</div> <div className="pm_info_value">{this.getTranslationByKey(list.sunday)}</div>
                    </div>
                </div>
                {list.extra_info ?
                    (list.page_id ?
                        <div className="extra_info_row" >
                            <div className="extra_info_button book pointer" onClick={() => this.props.select(this.getTranslationByKey(list.extra_info.text), 'page', list.extra_info)}>{this.getTranslationByKey(list.extra_info.text)}</div>
                        </div>
                        :
                        <div className="extra_info_row" >
                            <div className="extra_info_string book">{this.getTranslationByKey(list.extra_info.text)}</div>
                        </div>)
                    : ''}
            </div>
        </div>
        /**/
        return ret
    }

    splitInNChunks(array) {
        let n = window.innerWidth > 1400 ? 3 : 2
        let [...arr] = array;
        var res = [];
        while (arr.length) {
            res.push(arr.splice(0, n));
        }
        return res;
    }

    getInfoBoxWeb() {
        let ret = ''
        {/*<div className="flex" Style="padding: 0 10px;"> </div>*/ }
        let tmp = this.state.newInfo && this.state.newInfo.location_opening_hours ? this.splitInNChunks(this.state.newInfo.location_opening_hours) : ''
        ret = this.state.newInfo && this.state.newInfo.location_opening_hours ?
            tmp.map((t) =>
                <div className="flex" Style="place-content: center;">
                    {t.map((e) =>
                        <React.Fragment>
                            <div className="info_box_web">
                                <div className="info_box_web_title bold">{this.getTranslationByKey(e.name)}</div>
                                {e.name === "WS.LOCATION.INFO.CANTEEN" ? <div className="extra_info_button book pointer" Style="margin-top: -5px;" onClick={() => window.open(this.state.newInfo.canteen_menu_link, '_blank')}>(Menu)</div> : ''}
                                <div className="info_box_web_text " Style={e.name === "WS.LOCATION.INFO.CANTEEN" ? 'margin-top: 0;' : ''}>
                                    <div className="flex">
                                        <div className="info_box_web_col1">{this.getTranslationByKey('DAY.MONDAY')}</div>
                                        <div className="info_box_web_col2">{this.getTranslationByKey(e.monday)}</div>
                                    </div>
                                    <div className="flex">
                                        <div className="info_box_web_col1">{this.getTranslationByKey('DAY.TUESDAY')}</div>
                                        <div className="info_box_web_col2">{this.getTranslationByKey(e.tuesday)}</div>
                                    </div>
                                    <div className="flex">
                                        <div className="info_box_web_col1">{this.getTranslationByKey('DAY.WEDNESDAY')}</div>
                                        <div className="info_box_web_col2">{this.getTranslationByKey(e.wednesday)}</div>
                                    </div>
                                    <div className="flex">
                                        <div className="info_box_web_col1">{this.getTranslationByKey('DAY.THURSDAY')}</div>
                                        <div className="info_box_web_col2">{this.getTranslationByKey(e.thursday)}</div>
                                    </div>
                                    <div className="flex">
                                        <div className="info_box_web_col1">{this.getTranslationByKey('DAY.FRIDAY')}</div>
                                        <div className="info_box_web_col2">{this.getTranslationByKey(e.friday)}</div>
                                    </div>
                                    <div className="flex">
                                        <div className="info_box_web_col1">{this.getTranslationByKey('DAY.SATURDAY')}</div>
                                        <div className="info_box_web_col2">{this.getTranslationByKey(e.saturday)}</div>
                                    </div>
                                    <div className="flex">
                                        <div className="info_box_web_col1">{this.getTranslationByKey('DAY.SUNDAY')}</div>
                                        <div className="info_box_web_col2">{this.getTranslationByKey(e.sunday)}</div>
                                    </div>
                                </div>
                                <div >
                                    {e.extra_info ?
                                        (e.extra_info.page_id ?
                                            <div className="extra_info_row" Style="margin-top: 10px;">
                                                {/* da rendere dinamico la select */}
                                                <div className="extra_info_button book pointer" onClick={() => this.props.select('custom_pages', e.extra_info, 'extra_info_link')}>{this.getTranslationByKey(e.extra_info.text)}</div>
                                            </div> :
                                            <div className="extra_info_row" Style="margin-top: 10px;">
                                                <div className="extra_info_string book">{this.getTranslationByKey(e.extra_info.text)}</div>
                                            </div>)
                                        : ''}
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            )
            : ''
        let ret2 =
            <div Style="width: 100%">
                <Carousel
                    centerSlidePercentage={50}
                    interval={4000}
                    infiniteLoop={true}
                    transitionTime={1000}
                    showThumbs={false}
                    showStatus={false}
                    renderIndicator={(onClickHandler, isSelected, index, label) => {
                        const selected = { marginLeft: -10, color: "#535353", cursor: "pointer" };
                        const notSelected = { marginLeft: -10, color: "#c2c2c2", cursor: "pointer" };
                        const style = isSelected
                            ? { ...selected, color: "#535353" }
                            : { ...notSelected };
                        return (
                            <span
                                style={style}
                                onClick={onClickHandler}
                                onKeyDown={onClickHandler}
                                value={index}
                                key={index}
                                role="button"
                                tabIndex={0}
                                aria-label={`${label} ${index + 1}`}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-dot pointer" viewBox="0 0 16 16">
                                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                                </svg>

                            </span>
                        );
                    }}
                >
                    {ret}
                </Carousel>
            </div>
        return ret2
    }

    getBusRides() {
        let ret = ''
        let current_rides = [];
        if (!this.state.isLoading) {
            if (this.state.newInfo.bus_rides && this.state.newInfo.bus_rides.length > 0 && this.state.newInfo.bus_rides[0] && this.state.newInfo.bus_rides[0].current_rides) {
                this.state.newInfo.bus_rides[0].current_rides.map((elem) =>
                    current_rides.push(elem.hour)
                )
            }
        }

        ret =
            this.state.newInfo.bus_rides && this.state.newInfo.bus_rides.length == 0 ?
                ''
                :
                <React.Fragment key='bus' >
                    <div className="pm_info_submenu" key='bus' Style={"padding-left: 0px;"}>
                        <div className="pm_info_row_nav">
                            <div className=" info_utili_s ">{this.getTranslationByKey('WS.LOCATION.INFO.NAVETTA')}</div>
                        </div>
                    </div>

                    {this.state.newInfo.bus_rides.map((elem, index) =>
                        <div className="pm_bus_rides">
                            <React.Fragment key={index}>
                                <table className="pm_info_bus_table" key={elem.direction}>
                                    <thead>
                                        <tr>
                                            <th colSpan={5} className="pm_info_bus_table_th padt-5">{elem.direction}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {elem.rides.map((hours) =>
                                            <tr key={hours.hour} Style={current_rides.includes(hours.hour) ? (current_rides[0].includes(hours.hour) ? 'border-top: 2px solid #000; border-bottom: 2px solid rgba(0, 0, 0, .1);' : 'border-bottom: 2px solid #000; border-top: 2px solid rgba(0, 0, 0, .1);') : 'border-bottom: 0.5px solid rgba(0, 0, 0, .2)'}>
                                                <td className={current_rides.includes(hours.hour) ? "pm_info_bus_table_main_td" : "pm_info_bus_table_td"}>{hours.hour}</td>
                                                {hours.bus_rides.map((h, index) =>

                                                    <td key={'tr_' + hours.hour + '_' + index} className={current_rides.includes(hours.hour) ? "pm_info_bus_table_main_td" : "pm_info_bus_table_td"}>{h}</td>

                                                )}
                                            </tr>

                                        )}</tbody>
                                </table>
                                {elem.extra_info ?

                                    (elem.extra_info.page_id ?
                                        <div Style={"text-align: -webkit-center;"}>
                                            <div className="extra_info_button book pointer" onClick={() => this.props.select(this.getTranslationByKey(elem.extra_info.text), 'page', elem.extra_info)}>{this.getTranslationByKey(elem.extra_info.text)}</div>
                                        </div> :
                                        <div Style={"text-align: -webkit-center;"}>
                                            <div className="extra_info_string book">{this.getTranslationByKey(elem.extra_info.text)}</div>
                                        </div>)
                                    :
                                    <br />
                                }
                            </React.Fragment>
                        </div>
                    )
                    }
                </React.Fragment >
        return ret
    }

    handleDirection(index) {
        this.setState({
            currentRidesView: index
        })
    }

    getBusRidesWeb() {
        let ret = ''
        let current_rides = [];
        if (!this.state.isLoading) {
            if (this.state.newInfo && this.state.newInfo.bus_rides && this.state.newInfo.bus_rides.length > 0 && this.state.newInfo.bus_rides[0] && this.state.newInfo.bus_rides[0].current_rides) {
                this.state.newInfo.bus_rides[0].current_rides.map((elem) =>
                    current_rides.push(elem.hour)
                )
            }
        }

        ret = <React.Fragment key='bus'>

            {this.state.newInfo && this.state.newInfo.bus_rides && this.state.newInfo.bus_rides.length > 0 ?
                <div Style="width: 100%;">
                    <div className="bus_rides_web">
                        <div>
                            <div className="info_utili_s">{this.getTranslationByKey('WS.LOCATION.INFO.NAVETTA')}</div>
                        </div>
                        <div className="bus_rides_buttons">
                            <div className="flex">
                                {this.state.newInfo.bus_rides.map((br, index) =>
                                    <div className={index == this.state.currentRidesView ? "direction_button_web direction_button_web_selected pointer bold" : "direction_button_web pointer"} onClick={() => this.handleDirection(index)} >{br.direction}</div>
                                )}
                            </div>

                        </div>

                        <div>
                            {this.state.newInfo && this.state.newInfo.bus_rides && this.state.newInfo.bus_rides.length > 0 ?
                                this.state.newInfo.bus_rides.map((elem, index) =>
                                    index == this.state.currentRidesView ?
                                        <div Style="margin-top: 20px;">
                                            <React.Fragment key={index}>
                                                <table className="pm_info_bus_table" key={elem.direction}>
                                                    <tbody>
                                                        {elem.rides.map((hours) =>
                                                            <tr key={hours.hour} Style={current_rides.includes(hours.hour) ? (current_rides[0].includes(hours.hour) ? 'border-top: 2px solid #000; border-bottom: 2px solid rgba(0, 0, 0, .1);' : 'border-bottom: 2px solid #000; border-top: 2px solid rgba(0, 0, 0, .1);') : 'border-bottom: 0.5px solid rgba(0, 0, 0, .2)'}>
                                                                <td className={current_rides.includes(hours.hour) ? "pm_info_bus_table_main_td" : "pm_info_bus_table_td"}>{hours.hour}</td>
                                                                {hours.bus_rides.map((h, index) =>

                                                                    <td key={'tr_' + hours.hour + '_' + index} className={current_rides.includes(hours.hour) ? "pm_info_bus_table_main_td" : "pm_info_bus_table_td"}>{h}</td>

                                                                )}
                                                            </tr>

                                                        )}</tbody>
                                                </table>
                                                {elem.extra_info ?
                                                    (elem.extra_info.page_id ?
                                                        <div Style={"text-align: -webkit-center;"}>
                                                            <div className="extra_info_button book pointer" onClick={() => this.props.select(this.getTranslationByKey(elem.extra_info.text), 'page', elem.extra_info)}>{this.getTranslationByKey(elem.extra_info.text)}</div>
                                                        </div> :
                                                        <div Style={"text-align: -webkit-center;"}>
                                                            <div className="extra_info_string book">{this.getTranslationByKey(elem.extra_info.text)}</div>
                                                        </div>)
                                                    :
                                                    <br />
                                                }
                                            </React.Fragment>
                                        </div> : ''
                                )
                                : ''}
                        </div>
                    </div>
                </div>
                : ''}
        </React.Fragment>

        return ret
    }

    getContactsBox() {
        let ret = ''
        ret = this.state.newInfo.contacts ?
            <div>
                <div Style="margin-bottom: 10px;">
                    {this.state.newInfo && this.state.newInfo.map && this.state.newInfo.map.latitude && this.state.newInfo.map.longitude ?
                        <MapGoogle location={this.state.newInfo.map} ></MapGoogle>
                        : ''}
                </div>
                <div>
                    <div className="street_address_useful_info">
                        <a target="_blank" href={"https://www.google.it/maps/place/" + this.state.newInfo.street_address}>
                            {this.state.newInfo.street_address}
                        </a>
                    </div>
                    <div>
                        {this.state.newInfo.contacts.map((c) =>
                            <div Style="font-size: 14px;">
                                <Markdown>{c.name + ' ' + c.value}</Markdown>
                            </div>
                        )}
                    </div>
                </div>
            </div> : ''
        return ret
    }

    getContactsBoxWeb() {
        let ret = ''
        ret = <div Style="width: 100%; place-items: center; padding: 20px 0;">
            {this.state.newInfo ?
                <div Style="width: 100%;">
                    <div Style="margin-top: 20px;">
                        {this.state.newInfo && this.state.newInfo.map && this.state.newInfo.map.latitude && this.state.newInfo.map.longitude ?
                            <MapGoogle location={this.state.newInfo.map}></MapGoogle>
                            : ''}
                    </div>
                    <div className="contacts_web_container">
                        <div className="bold">{this.state.newInfo.location_name ?? ''}</div>
                        <div>{this.state.newInfo.street_address ?? ''}</div>
                        {this.state.newInfo && this.state.newInfo.contacts ? this.state.newInfo.contacts.map((c) =>
                            <div>
                                <Markdown>{c.name + ' ' + c.value}</Markdown>
                            </div>
                        ) : ''}
                    </div>
                </div> : ''}
        </div>
        return ret
    }

    render() {

        /* mi serve per evidenziare le fasce orarie della navetta */
        let current_rides = [];
        if (!this.state.isLoading) {
            if (this.state.busRides && this.state.busRides.length > 0 && this.state.busRides[0]) {
                this.state.busRides[0].current_rides.map((elem) =>
                    current_rides.push(elem.hour)
                )
            }
        }

        return (
            this.state.isLoading ?
                <LoadingComponentWeb />
                :
                <React.Fragment key={'office' + this.props.office.id}>
                    <div className="flex">
                        <div Style="width: 100%;">
                            <div className="location_info_container">
                                {window.innerWidth > 1000 ? this.getContactsBoxWeb() : this.getContactsBox()}
                                {this.state.newInfo && this.state.newInfo.location_opening_hours ?
                                    this.state.newInfo.location_opening_hours.map((n) =>
                                        window.innerWidth > 1000 ? '' : this.getInfoBox(n)
                                    ) : ''}
                                {/* Orari servizio navetta */}
                                {window.innerWidth > 1000 ? this.getInfoBoxWeb() : ''}
                                {window.innerWidth > 1000 ? this.getBusRidesWeb() : this.getBusRides()}
                            </div>
                        </div>
                    </div >

                </React.Fragment >
        )
    }
}
export default InfoUtili