export default function DateSlotCard(props) {
    const getDuration = (d) => {
        let res = ''
        if (new Date(d.start).getHours() == 0 && new Date(d.start).getMinutes() == 0 && new Date(d.end).getHours() == 0 && new Date(d.end).getMinutes() == 0) {
            res = Math.round((d.end - d.start) / (1000 * 60 * 60 * 24)).toString().replace('.', ',') + (Math.round((d.end - d.start) / (1000 * 60 * 60 * 24)) > 1 ? ' days' : ' day')
        } else
            res = (Math.abs(d.end - d.start) / 36e5).toString().replace('.', ',') + ((Math.abs(d.end - d.start) / 36e5) > 1 ? ' hours' : ' hour')
        return res
    }
    return (
        <div className="recap_slot_card">

            <div className="bold">Slot Date</div>
            <div className="recap_slot_value">
                {new Date(props.date.start).toLocaleDateString() + ' ' + new Date(props.date.start).getHours().toString().padStart(2, '0') + ':' + new Date(props.date.start).getMinutes().toString().padStart(2, '0')}
            </div>


            <div className="bold"> Duration</div>
            <div className="recap_slot_value">{getDuration(props.date)}</div>
            <div className="bold">Max Participants</div>
            <div className="recap_slot_value"> {props.date.max_participants}</div>
        </div>
    )
}