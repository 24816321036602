import Markdown from "markdown-to-jsx";
import { OpportunitiesService } from "../Services/Services";
import { getTranslationByKey } from "../global";

export function OpportunitiesCard(props) {

    const OS = new OpportunitiesService()

    const add = (e) => {
        e.stopPropagation()
        props.add(e, props.card)
    }

    const remove = (e) => {
        e.stopPropagation()
        props.remove(e, props.card)
    }

    const handleViewAll = (e) => {
        e.stopPropagation()
        props.handleViewAll(props.title)
    }

    return (
        //full_opportunity_box
        <div className={(props.card && (props.card.is_draft || props.card.is_closed) ? "in_approval_opportunity_box" : (props.card && props.card.is_opportunity_admin && !props.userView ? "admin_opportunity_box pointer" : "opportunities_box  pointer")) + (props.viewAll ? ' opportunities_box_viewall' : '')}
            onClick={!props.card ? (e) => handleViewAll(e) : () => props.handleOpportunityDetail(props.card)}>
            {!props.card ?
                ''
                :
                <div className="flex">
                    <div className={("bold card_opportunities_title") + (props.viewAll ? ' card_opportunities_title_viewall' : '')} >{props.card.title}</div>
                    <div Style="margin-left: auto;">{
                        props.card.is_opportunity_admin && !props.userView ? <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-person-gear" viewBox="0 0 16 16">
                            <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m.256 7a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382zM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0" />
                        </svg> : (
                            !props.card.is_in_wishlist ?
                                <div>
                                    <svg onClick={(e) => add(e)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                                        <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                                    </svg>
                                </div>
                                :
                                <div>
                                    <svg onClick={(e) => remove(e)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart-fill" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314" />
                                    </svg>
                                </div>
                        )}
                    </div>
                </div >}
            {
                !props.card ? <div className="opportunities_box_view_all" Style="font-size: 14px" >View all</div>
                    : <div className={(props.tag ? "opportunities_box_description" : 'opportunities_box_description_notag') + (props.viewAll ? (props.tag ? ' opportunities_box_description_viewall' : ' opportunities_box_description_notag_viewall') : '')} Style="font-size: 14px">
                        <div className="flex mt-1">  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="19" fill="currentColor" class="bi bi-geo-fill mr-5" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.3 1.3 0 0 0-.37.265.3.3 0 0 0-.057.09V14l.002.008.016.033a.6.6 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.6.6 0 0 0 .146-.15l.015-.033L12 14v-.004a.3.3 0 0 0-.057-.09 1.3 1.3 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465s-2.462-.172-3.34-.465c-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411" />
                        </svg>
                            <div className={props.viewAll ? "opportunities_box_position opportunities_box_position_view_all" : "opportunities_box_position"}>{props.card.location ?? '-'}</div>
                        </div>
                        <div className="flex mt-1"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="19" fill="currentColor" class="bi bi-clock mr-5" viewBox="0 0 16 16">
                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
                        </svg>{props.card.duration_string ?? '-'}</div>
                        <div className="flex mt-1">
                            {/*<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-person-vcard mr-5" viewBox="0 0 16 16">
                            <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4m4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5M9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8m1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5" />
                            <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96q.04-.245.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 1 1 12z" />
                        </svg>{props.card.job_role ?? '-'}*/}</div>
                        {/*window.innerWidth > 1000 ? <Markdown>{props.card.description}</Markdown> : ''*/}
                    </div>
            }
            {props.tag && props.card && props.card.type && props.card.type.name ? <div className="opportunities_tag_cont" Style={props.card.is_closed ? 'background-color: #808080;' : ''}> {props.card.type && props.card.type.name && !props.card.is_deletion_requested ? props.card.type.name : ''}   {props.card && props.card.is_draft ? getTranslationByKey('OPPORTUNITIES.LABLE.INAPPROVAL') : (props.card && props.card.is_closed ? getTranslationByKey('OPPORTUNITIES.LABLE.CLOSED') : (props.card && props.card.is_deletion_requested ? getTranslationByKey('OPPORTUNITIES.LABLE.WAITINGDELETE') : ''))}</div> : ''}

        </div >
    )
}
