import { Component } from 'react';
import Select from 'react-select';
import { FixedSizeList as List } from "react-window";
import { customStyles } from '../global';
export default function SelectComponent(props) {

    const handleChange = (e) => {
        if (e && e.value)
            props.setSelectedOption(e.value, props.field)
        else
            props.setSelectedOption(e, props.field)
    }

    return (
        <Select
            value={props.selectedOption && props.selectedOption.label ? props.selectedOption : ''}
            onChange={handleChange}
            options={props.options}
            className="right_tab_select opportunities_input opportunities_input_loc_select"
            styles={customStyles}
            components={MenuList}
            isClearable={true}

        />
    )
}
const height = 3;
class MenuList extends Component {

    render() {
        const { options, children, maxHeight, getValue } = this.props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * height;

        return (
            <List
                height={maxHeight}
                itemCount={children.length}
                itemSize={height}
                initialScrollOffset={initialOffset}
            >
                {({ index, style }) => <div style={style}>{children[index]}</div>}
            </List>
        );
    }
}

const options = Array.from({ length: 10000 }, (_, i) => ({
    value: i,
    label: `Option ${i}`
}));
