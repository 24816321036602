import ScrollContainer from 'react-indiana-drag-scroll'
import { OpportunitiesCard } from './OpportunitiesCard'
import { Carousel } from 'react-responsive-carousel';
import React from 'react';
import { getTranslationByKey } from "../global"

export function OpportunitiesSection(props) {


    const splitInNChunks = (array) => {
        let n = 3
        let [...arr] = array;
        var res = [];
        while (arr.length) {
            res.push(arr.splice(0, n));
        }
        return res;
    }

    const handleViewAll = (e) => {
        e.stopPropagation()
        props.handleViewAll(props.title)
    }

    let view_all_card = <OpportunitiesCard userView={props.userView} reload={props.reload} card={null} tag={false} title={props.title} handleViewAll={props.handleViewAll} add={props.add} remove={props.remove} selectedType={props.selectedType} />
    const getCarousel = () => {
        let ret = ''
        let tmp = props.list && props.list.length > 0 ? splitInNChunks(props.list) : ''
        ret = props.list && props.list.length > 0 ?
            tmp.map((t, i) =>
                <div className="flex opportunities_carousel_content" Style="padding-left: 30px;">
                    <React.Fragment>
                        {t.map((card) =>
                            <OpportunitiesCard viewAll={props.viewAll} reloadWishlist={props.reloadWishlist} userView={props.userView} key={card.id} reload={props.reload} card={card} handleOpportunityDetail={props.handleOpportunityDetail} tag={false} handleViewAll={props.handleViewAll} add={props.add} remove={props.remove} selectedType={props.selectedType} />

                        )}
                        {/*props.has_more && i == (tmp.length - 1) ? view_all_card : ''*/}
                    </React.Fragment>
                </div>
            )
            : ''
        let ret2 =
            <div Style="width: 95%">
                <Carousel
                    centerSlidePercentage={50}
                    interval={4000}
                    infiniteLoop={true}
                    transitionTime={1000}
                    showThumbs={false}
                    showStatus={false}
                    renderArrowPrev={(clickHandler, hasPrev) => {
                        return (
                            <div Style="min-width: 22px;" className='pointer' onClick={clickHandler}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
                                </svg>
                            </div>
                        );
                    }}
                    renderArrowNext={(clickHandler, hasNext) => {
                        return (
                            <div Style="min-width: 22px;" className='pointer' onClick={clickHandler} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                                </svg>
                            </div>
                        );
                    }}
                    renderIndicator={(onClickHandler, isSelected, index, label) => {
                        const selected = { marginLeft: -10, color: "#535353", cursor: "pointer" };
                        const notSelected = { marginLeft: -10, color: "#c2c2c2", cursor: "pointer" };
                        const style = isSelected
                            ? { ...selected, color: "#535353" }
                            : { ...notSelected };
                        return (
                            <span
                                style={style}
                                onClick={onClickHandler}
                                onKeyDown={onClickHandler}
                                value={index}
                                key={index}
                                role="button"
                                tabIndex={0}
                                aria-label={`${label} ${index + 1}`}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-dot pointer" viewBox="0 0 16 16">
                                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                                </svg>

                            </span>
                        );
                    }}
                >
                    {ret}
                </Carousel>
            </div>
        let ret3 = <ScrollContainer className="flex">
            {props.list && props.list.length > 0 ?
                props.list.map((card) =>
                    <OpportunitiesCard userView={props.userView} key={card.id} reload={props.reload} card={card} handleOpportunityDetail={props.handleOpportunityDetail} tag={false} handleViewAll={props.handleViewAll} add={props.add} remove={props.remove} selectedType={props.selectedType} />

                ) : ''}
        </ScrollContainer>
        if (window.innerWidth > 1000)
            return ret2
        else
            return ret3
    }

    let experiences = ''
    if (props.list && props.list.length > 0)
        experiences = getCarousel()


    return (
        <div className="opportunities_section">
            <div>
                <div className="bold uppercase opportunities_section_title">{props.title.name}</div>
                <div className='view_all_link pointer' onClick={(e) => handleViewAll(e)}>{getTranslationByKey('OPPORTUNITIES.BUTTON.VIEWALL')}</div>
            </div>
            <div className="opportunities_boxes_container">

                {experiences}
                {/*props.has_more ? view_all_card : ''*/}

            </div>
        </div>
    )

}