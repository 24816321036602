import React, { Component, useEffect, useState } from "react";
import { BusinessTravelService, DeskService } from "../Services/Services";
import { getTranslationByKey } from "../global";
import Markdown from 'markdown-to-jsx'
export function OpportunitiesFaq(props) {
    let ds = new DeskService()
    let bs = new BusinessTravelService()
    const [load, setLoading] = React.useState(false)
    const [info, setInfo] = React.useState([])

    useEffect(() => {
        if (props.faq_page_id) {
            ds.getPagebyId(props.faq_page_id).then((data) => {
                setInfo(data)
            })
        }
    }, [])

    //download file api
    const getDocument = (url) => {
        setLoading(true)
        bs.getDocument(url, true).then(() => {
            setLoading(false)
        })
    }

    //desktop variables
    let content = ''
    if (info.content)
        content = info.content.map((c) =>
            !c.button_link ?
                (c.countdown_image ?
                    <div Style={"text-align: -webkit-center; margin-top: 10px;margin-bottom:20px"}>
                        <div className={window.innerWidth < 1000 ? "pointer event_image_container" : "pointer  event_image_container_web"} Style={"background-image:url('" + (window.innerWidth < 1000 ? c.countdown_image.image_url : c.countdown_image.web_image_url) + "');"}>

                        </div>
                    </div > : (c.text ?
                        <div className="office_page_subtitle mb-3">
                            <Markdown>{getTranslationByKey(c.text)}</Markdown>
                        </div> : (c.title ? <div className="bold mt-2 mb-2">
                            {getTranslationByKey(c.title)}
                        </div> : <div className="office_page_subtitle">
                            <img className='w-100' src={c.image}></img>
                        </div>)
                    ))
                :
                (c.button_link.external_url ? <div Style={"text-align: -webkit-center; margin-top: 10px;margin-bottom:20px"}>
                    <div className="pointer text_sub_menu_container" onClick={() => window.open(c.button_link.external_url, '_blank')}>
                        <div className="text_sub_menu desk_menu">
                            {getTranslationByKey(c.button_link.text)}
                        </div>
                    </div>
                </div> : (c.button_link.file_url ? <div Style={"text-align: -webkit-center; margin-top:10px;margin-bottom:20px"}>
                    <div className="pointer text_sub_menu_container" onClick={() => getDocument(c.button_link.file_url)}>
                        <div className="text_sub_menu desk_menu">
                            {getTranslationByKey(c.button_link.text)}
                        </div>
                    </div>
                </div> : ''))
        )

    let default_content = ''
    if (info.default_content)
        default_content = info.default_content.map((c) =>
            !c.button_link ?
                (c.text ?
                    <div className="office_page_subtitle mb-3">
                        {getTranslationByKey(c.text)}
                    </div> : (c.title ? <div className="office_page_title mt-2 mb-2">
                        {getTranslationByKey(c.title)}
                    </div> : <div className="office_page_subtitle">
                        <img className='w-100' src={c.image}></img>
                    </div>)
                )
                :
                (c.button_link.external_url ? <div Style={"text-align: -webkit-center; margin-top: 10px;margin-bottom:20px"}>
                    <div className="pointer text_sub_menu_container" onClick={() => window.open(c.button_link.external_url, '_blank')}>
                        <div className="text_sub_menu desk_menu">
                            {getTranslationByKey(c.button_link.text)}
                        </div>
                    </div>
                </div> : '')
        )


    return (
        <div Style={'padding-bottom:30px; '}>
            {load ? '' : default_content !== '' ? default_content : (content !== '' ? content : '')}
            <div></div>
        </div>
    )
}