import Markdown from "markdown-to-jsx";
export default function DisabledTextBox(props) {

    return (
        props.label === 'Description' ? <div className="disabled_text_box_cont_des">

            <div className="bold">{props.label} </div>
            <div className="description_opportunities_ellipsis"> <Markdown>{props.value}</Markdown></div>
        </div>
            : <div className="disabled_text_box_cont">
                <div className="bold">{props.label} </div>

                <input type="text" className="opportunities_input" value={props.value} disabled />
            </div>

    )
}